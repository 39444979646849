import { Card, Input, Button, notification, Form } from 'antd'
import { VerifiedOutlined } from '@ant-design/icons'
import propman_white_clear from 'assets/logo-with-name.png'
import React, { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'config/constants'
import { trimWhitespace } from 'utils'

const VerifyEmailPage: React.FC = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [waitTime, setWaitTime] = useState(0)
  useEffect(() => {
    let interval: NodeJS.Timeout

    if (waitTime > 0) {
      interval = setInterval(() => {
        setWaitTime((prevTime) => prevTime - 1)
      }, 1000)
    }

    return () => { clearInterval(interval) }
  }, [waitTime])
  const requestVerificationCode = async () => {
    try {
      setLoading(true)
      await Auth.verifyCurrentUserAttribute('email')
      setWaitTime(60)
      notification.success({
        message: 'Success',
        description: 'Email verification code sent.'
      })
    } catch (error: any) {
      notification.error({
        message: 'Error Sending Code',
        description: error?.message || 'There was an error sending the verification code.'
      })
    } finally {
      setLoading(false)
    }
  }
  const handleEmailVerification = async (values: { code: string }) => {
    try {
      setLoading(true)
      await Auth.verifyCurrentUserAttributeSubmit('email', values.code)
      navigate(ROUTES.HOME)
      notification.success({
        message: 'Success',
        description: 'Email verified successfully.'
      })
    } catch (error: any) {
      notification.error({
        message: 'Verification Error',
        description: error?.message || 'There was an error verifying your email.'
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Card className='login-container' style={{ width: 400, textAlign: 'center', margin: '100px auto' }}>
      <div style={{ marginBottom: 50 }}>
        <img src={propman_white_clear} alt="Logo" style={{ width: 250 }} />
      </div>
      <Button
        className='button'
        style={{ marginBottom: '10px', color: 'white' }}
        block
        type="primary"
        onClick={requestVerificationCode}
        loading={loading}
        disabled={waitTime > 0}
      >
        {waitTime > 0 ? `Wait for ${waitTime}s` : 'Click Me to Request Verification Code'}
      </Button>
    <Form onFinish={handleEmailVerification}>
        <Form.Item name="code" getValueFromEvent={trimWhitespace} rules={[{ required: true, message: 'Please input the verification code!' }]}>
        <Input className='input-field'
            prefix={<VerifiedOutlined />}
            placeholder="Verification Code"
            style={{ marginBottom: 20 }}
        />
        </Form.Item>
        <Form.Item>
        <Button className='button' block type="primary" htmlType="submit" loading={loading}>
            Verify Email
        </Button>
        </Form.Item>
    </Form>

    </Card>
  )
}

export { VerifyEmailPage }
