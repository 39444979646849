import React, { useState } from 'react'
import { Form, Button, Typography, Table, message, Upload, Tag, Space } from 'antd'
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons'
import Papa from 'papaparse'
import { uploadUsersFromCSV } from 'services'
import { Disclaimer, PageTitle } from 'components'
import { useNotifications } from 'hooks'

const { Title } = Typography

const UploadUsers: React.FC = () => {
  const [form] = Form.useForm()
  const { addNotification } = useNotifications()
  const [loading, setLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [fileValidationMessage, setFileValidationMessage] = useState<string>('')
  const [parsedData, setParsedData] = useState<any[]>([])
  const [JSONData, setJSONData] = useState<Record<string, any[]>>({})

  const validateEmail = (email: string): boolean => {
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return pattern.test(email)
  }

  const validateGroupName = (groupName: string): boolean => {
    const pattern = /^[A-Za-z0-9_]+$/
    if (groupName === '') {
      return true
    }
    return pattern.test(groupName)
  }

  const validateName = (name: string): boolean => {
    const pattern = /^[a-zA-Z\s]+$/
    return pattern.test(name)
  }
  const validateOrgId = (orgID: string): boolean => {
    const pattern = /^[A-Z]{5}\d{3}$/
    return pattern.test(orgID)
  }
  const renderValidationTag = (isValid: boolean): JSX.Element => {
    return isValid
      ? (
                <Tag color="green">Valid</Tag>
        )
      : (
                <Tag color="red">Invalid</Tag>
        )
  }

  const validateAndHighlightCells = (record: any, dataIndex: string): JSX.Element => {
    let isValid = true
    const value = record[dataIndex]
    if (dataIndex === 'email') {
      isValid = validateEmail(value)
    } else if (dataIndex === 'group_name') {
      isValid = validateGroupName(value)
    } else if (dataIndex === 'given_name' || dataIndex === 'family_name') {
      isValid = validateName(value)
    } else if (dataIndex === 'org_id') {
      isValid = validateOrgId(value)
    }
    return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ opacity: isValid ? 1 : 0.5, marginRight: '8px' }}>{value}</div>
                {renderValidationTag(isValid)}
            </div>
    )
  }

  const styledColumns = [
    {
      title: 'Given Name',
      dataIndex: 'given_name',
      sorter: (a: any, b: any) => a.given_name.localeCompare(b.given_name),
      render: (text: string, record: any) => validateAndHighlightCells(record, 'given_name')
    },
    {
      title: 'Family Name',
      dataIndex: 'family_name',
      sorter: (a: any, b: any) => a.family_name.localeCompare(b.family_name),
      render: (text: string, record: any) => validateAndHighlightCells(record, 'family_name')
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: (a: any, b: any) => a.email.localeCompare(b.email),
      render: (text: string, record: any) => validateAndHighlightCells(record, 'email')
    },
    {
      title: 'Group Name',
      dataIndex: 'group_name',
      sorter: (a: any, b: any) => a.group_name.localeCompare(b.group_name),
      render: (text: string, record: any) => validateAndHighlightCells(record, 'group_name')
    },
    {
      title: 'Organization ID',
      dataIndex: 'org_id',
      render: (text: string, record: any) => validateAndHighlightCells(record, 'org_id')
    }
  ]

  const validateColumns = (data: any[]) => {
    const columns = ['given_name', 'family_name', 'email', 'group_name', 'org_id']
    const fileColumns = data
    for (const col of columns) {
      if (!fileColumns.includes(col)) {
        return false
      }
    }
    return true
  }

  const handleUpload = async () => {
    if (!selectedFile || Object.keys(JSONData).length === 0) {
      void message.error('Please select a CSV file first.')
      return
    }
    // Implement the upload logic here
    try {
      setLoading(true)
      const response = await uploadUsersFromCSV({ users: JSONData.users })
      if (response.includes('fail')) {
        try {
          addNotification({
            id: Date.now(),
            type: 'info',
            message: response || 'File uploaded successfully with some fails.',
            timestamp: Date.now()
          })
        } catch (innerError) {
          console.log(innerError)
        }
        void message.info(response || 'File uploaded successfully with some fails.')
      } else {
        try {
          addNotification({
            id: Date.now(),
            type: 'info',
            message: response || 'File uploaded successfully.',
            timestamp: Date.now()
          })
        } catch (innerError) {
          console.log(innerError)
        }
        void message.info(response || 'File uploaded successfully.')
        setParsedData([])
        form.resetFields()
      }
    } catch (error: any) {
      try {
        addNotification({
          id: Date.now(),
          type: 'error',
          message: error?.message || 'File uploaded failed.',
          timestamp: Date.now()
        })
      } catch (innerError) {
        console.log(innerError)
      }
      void message.error(error?.message || 'File uploaded failed.')
    } finally {
      setLoading(false)
    }
  }
  const convertToJSON = (data: any[]) => {
    const arrayOfObjects = convertToArrayOfObjects(data)
    return { users: arrayOfObjects }
  }
  const convertToArrayOfObjects = (data: any[]) => {
    const headers = data[0]
    const rows = data.slice(1)
    return rows.map(row => {
      const obj: any = {}
      row.forEach((value: any, index: any) => {
        obj[headers[index]] = value
      })
      return obj
    })
  }
  const onUploadChange = (info: any) => {
    const file = info.file
    if (!file || !(file instanceof File)) {
      void message.error('Failed to get the selected file.')
      return
    }

    Papa.parse(file, {
      skipEmptyLines: true,
      complete: (result: any) => {
        if (result.data && result.data.length > 0 && validateColumns(result.data[0])) {
          setSelectedFile(file)
          setFileValidationMessage('File is a valid csv. Note the actual content validations below.')
          const formattedData = convertToArrayOfObjects(result.data)
          setParsedData(formattedData)
          const jsonData = convertToJSON(result.data)
          setJSONData(jsonData)
        } else {
          setFileValidationMessage('CSV does not have the required columns.')
          setParsedData([])
          setJSONData({})
        }
      },
      error: (error: any) => {
        try {
          addNotification({
            id: Date.now(),
            type: 'error',
            message: `${error}`,
            timestamp: Date.now()
          })
        } catch (innerError) {
          console.log(innerError)
        }
        void message.error(`Failed to parse CSV: ${error.message}`)
      }
    })
  }

  return (
        <div>
            <PageTitle text="Upload and Create Users from CSV file" />

            <Form
            form={form}
                name="upload-users"
                layout="vertical"
                style={{ maxWidth: '600px' }}
            >
                         <Disclaimer
                title="⚠️ Important Note"
                message="Please ensure the following columns are present: given_name, family_name, email, group_name, org_id. Also, it is important to know that if the group does not already exist, you have to create it, else, the user will be created but not added to a group"
            />
                <Form.Item
                    label="Users CSV File"
                    name="csv"
                    valuePropName="file"
                    rules={[{ required: true, message: 'Please upload a CSV file!' }]}
                >
                    <Upload
                        showUploadList={false}
                        onChange={onUploadChange}
                        beforeUpload={(file) => {
                          const isCsv = file.type === 'text/csv'
                          if (!isCsv) {
                            void message.error('You can only upload CSV files!')
                            return false
                          }

                          const isLt2M = file.size / 1024 / 1024 < 1
                          if (!isLt2M) {
                            void message.error('File must be smaller than 1MB!')
                            return false
                          }

                          return false // This is important to prevent automatic upload.
                        }}
                    >
                        <Space>
                            <Button icon={<UploadOutlined />}>Select CSV</Button>
                        </Space>
                    </Upload>
                </Form.Item>
                {selectedFile && <div><b>Selected File:</b> {selectedFile.name}</div>}
                {fileValidationMessage && <div style={{
                  color: fileValidationMessage.includes('CSV does not have the required columns.') ? 'red' : 'green' // Set the color based on the validation message
                }}><b>Validation:</b> {fileValidationMessage}</div>}
                <Form.Item>
                    <Button className='button' type="primary" onClick={handleUpload} loading={loading}>
                    Create Users
                    </Button>
                </Form.Item>
            </Form>
            {parsedData.length > 0 && (
                <>
                    <Title level={4}>Parsed Results:</Title>
                    <Table
                        dataSource={parsedData}
                        columns={styledColumns}
                        rowKey="email"
                        pagination={false}
                        bordered
                    // style={{
                    //   backgroundColor: '#4B0082', color: 'white'
                    // }}
                    // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
                    />
                </>
            )}
                                        <Button
                                icon={<DownloadOutlined />}
                                href={`${process.env.PUBLIC_URL}/sample.csv`}
                                download
                                style={{ margin: '10px 0' }}
                            >
                                Download Sample
                            </Button>
        </div>
  )
}

export default UploadUsers
