// AdminPortal.tsx
import React, { useContext, useState } from 'react'
import {
  Avatar,
  Layout,
  Menu,
  message
} from 'antd'
import {
  UserAddOutlined,
  OrderedListOutlined,
  UserOutlined,
  EditOutlined,
  LogoutOutlined,
  FileExcelTwoTone,
  CloudUploadOutlined
} from '@ant-design/icons'
import CreateCognitoUserGroup from './CREATE/CreateCognitoUserGroup'
import ListUsers from './READ/ListUsers'
import CreateUser from './CREATE/CreateUser'
import UploadUsers from './CREATE/UploadUsers'
import UpdateUser from './UPDATE/UpdateUser'
import ListAdminReports from './READ/ListAdminReports'
import { signOut } from 'components/pages/auth/SignOut'
import UploadReports from './CREATE/UploadReports'
import { AppContext, UserContext } from 'context'
import { Create, ListAltOutlined, Notifications, SentimentNeutralOutlined, Settings } from '@mui/icons-material'
import SettingsPage from './Modals/SettingsPage'
import NotificationsPage from './Modals/NotificationsPage'
import ListCognitoGroups from './READ/ListCognitoGroups'
import { appStateEnums } from 'appConstants'
import { ROUTES } from 'config/constants'
import { useNavigate } from 'react-router-dom'
import CreatePropmenOrg from './CREATE/CreatePropmanOrg'
import ListPropmanOrgs from './READ/ListPropmanOrgs'
import ListRlsTable from './READ/ListRlsTable'
const { Content, Footer, Sider } = Layout

const COMPONENT_MAP: any = {
  listPropmanOrgs: <ListPropmanOrgs />,
  createCognitoUserGroup: <CreateCognitoUserGroup />,
  listReports: <ListAdminReports />,
  listUsers: <ListUsers />,
  createUser: <CreateUser />,
  createPropmenOrg: <CreatePropmenOrg/>,
  uploadReports: <UploadReports />,
  uploadUsers: <UploadUsers />,
  updateUser: <UpdateUser />,
  settings: <SettingsPage />,
  notifications: <NotificationsPage />,
  listCognitoGroups: <ListCognitoGroups />,
  rlsTable: <ListRlsTable />
}

const AdminPortal: React.FC = () => {
  const navigate = useNavigate()
  const { state: appState, dispatch: appDispatch } = useContext(AppContext)
  const toggleCollapsed = function (collapsed: boolean) {
    appDispatch({ type: appStateEnums.COLLAPSE, collapsed })
  }
  const { state } = useContext(UserContext)
  const { user } = state

  const [selectedKey, setSelectedKey] = useState<string>('createUser')
  const onMenuClick = (key: string) => {
    setSelectedKey(key)
  }
  const getInitials = (givenName: string, familyName: string) => {
    return `${givenName?.charAt(0) || ''}${familyName?.charAt(0) || ''}`.toUpperCase()
  }

  const menuItems = [
    {
      label: 'User Management',
      key: 'user',
      icon: <UserOutlined />,
      children: [
        {
          label: 'Create User',
          key: 'createUser',
          icon: <UserAddOutlined />
        },
        {
          label: 'Upload Users',
          key: 'uploadUsers',
          icon: <CloudUploadOutlined />
        },
        {
          label: 'Create User Group',
          key: 'createCognitoUserGroup',
          icon: <UserAddOutlined />
        },
        {
          label: 'Update User',
          key: 'updateUser',
          icon: <EditOutlined />
        },
        {
          label: 'List Users',
          key: 'listUsers',
          icon: <OrderedListOutlined />
        },
        {
          label: 'List User Groups',
          key: 'listCognitoGroups',
          icon: <ListAltOutlined />
        }
      ]
    },
    {
      label: 'Quicksight Reports',
      key: 'reports',
      icon: <FileExcelTwoTone />,
      children: [
        {
          label: 'Upload Reports',
          key: 'uploadReports',
          icon: <CloudUploadOutlined />
        },
        {
          label: 'List Reports',
          key: 'listReports',
          icon: <OrderedListOutlined />
        }
      ]
    },
    {
      label: 'Propman Orgs',
      key: 'propman_orgs',
      icon: <FileExcelTwoTone />,
      children: [
        {
          label: 'Create Propman ID',
          key: 'createPropmenOrg',
          icon: <Create />
        },
        {
          label: 'List Orgs',
          key: 'listPropmanOrgs',
          icon: <OrderedListOutlined />
        },
        {
          label: 'RLS Table',
          key: 'rlsTable',
          icon: <OrderedListOutlined />
        }
      ]
    },
    {
      label: 'Simulate Client',
      key: 'clientPortal',
      icon: <SentimentNeutralOutlined />,
      onClick: async () => {
        try {
          navigate(ROUTES.DASHBOARD)
        } catch (error: any) {
          void message.error(error || 'There was an error simulating client')
        }
      }
    },
    {
      label: 'Settings',
      key: 'settings',
      icon: <Settings />
    },
    {
      label: 'Notifications',
      key: 'notifications',
      icon: <Notifications />
    },
    {
      label: 'Logout',
      key: 'logOut',
      icon: <LogoutOutlined />,
      onClick: async () => {
        try {
          await signOut()
        } catch (error: any) {
          void message.error(error?.message || 'There was an error signing out')
        }
      }
    }
  ]
  return (
    <Layout className="admin-layout cardsBody">
      <Layout>
        <Sider
          width={300}
          style={{
            borderTopRightRadius: '20px',
            borderBottomRightRadius: '20px',
            overflow: 'hidden'
          }}
          collapsible
          collapsed={appState.collapsed}
          onCollapse={toggleCollapsed}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '10px',
              gap: '10px'
            }}
          >
            <Avatar
              src={user?.avatarUrl}
              size={32}
              icon={
                !user?.avatarUrl &&
                (appState.collapsed
                  ? (
                      getInitials(user?.given_name ?? '', user?.family_name ?? '')
                    )
                  : (
                  <UserOutlined />
                    ))
              }
            />
            {!appState.collapsed && (
              <span style={{ color: 'white' }}>{`${user?.given_name} ${user?.family_name}`}</span>
            )}
          </div>
          <Menu
            theme={appState.theme}
            mode="inline"
            defaultSelectedKeys={['createUser']}
            className="menu"
            items={menuItems}
            onClick={({ key }) => {
              onMenuClick(key)
            }}
          />
        </Sider>
        <Layout className="content-layout">
          <Content className="site-layout-background">
            {COMPONENT_MAP[selectedKey] || (
              <div className="not-implemented">Not implemented</div>
            )}
          </Content>
        </Layout>
      </Layout>
      <Footer className="admin-footer">
        ©2024 Grosvenor Systems Admin Portal. All Rights Reserved.
      </Footer>
    </Layout>
  )
}

export default AdminPortal
