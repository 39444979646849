import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Modal, Table, message, Space, notification } from 'antd'
import type { ADMIN_REPORT, USER_DT } from 'types'
import { PageTitle } from 'components'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import { useNotifications } from 'hooks'
import { listReportUsers } from 'services'
type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

interface GroupColumn {
  key: string
  title: string
  dataIndex: string
  sorter?: (a: any, b: any) => number
  responsive?: Breakpoint[]
  render?: (text: any, report: any) => JSX.Element
}

interface ReportUsersModalProps {
  visible: boolean
  report: ADMIN_REPORT
  onClose: () => void
}

const ReportUsersModal: React.FC<ReportUsersModalProps> = ({ visible, report, onClose }) => {
  const [loading, setLoading] = useState(false)
  const { addNotification } = useNotifications()
  const [reportUsers, setReportUsers] = useState<USER_DT[]>([])
  const [selectedGroups, setSelectedGroups] = useState<USER_DT[]>([])

  const userReportsColumns: GroupColumn[] = [
    // {
    //   key: 'id',
    //   title: 'Select',
    //   dataIndex: 'id',
    //   render: (_: any, record: any) => <Checkbox style={{
    //   }} onChange={e => { handleSelect(e, record) }} />
    // },
    {
      title: 'Given Name',
      dataIndex: 'given_name',
      key: 'given_name',
      sorter: (a, b) => a.given_name.localeCompare(b.given_name)
    },
    {
      title: 'Family Name',
      dataIndex: 'family_name',
      key: 'family_name'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    }
  ]

  useEffect(() => {
    const fetchData = async () => {
      if (!report?.report_id) {
        return
      }
      try {
        setLoading(true)
        const listOfReportUsers = await listReportUsers({ report_id: report?.report_id })
        setReportUsers(listOfReportUsers)
      } catch (error) {
        console.error('Error fetching the users', error)
        try {
          addNotification({
            id: Date.now(),
            type: 'error',
            message: `${error}`,
            timestamp: Date.now()
          })
        } catch (innerError) {
          console.log(innerError)
        }
        notification.error({
          message: 'Error getting report users',
          description: `${error}.`
        })
      } finally {
        setLoading(false)
      }
    }

    void fetchData()
  }, [])

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
    >
      <PageTitle text="Report Users" />
      {/* {
                (selectedGroups && selectedGroups.length > 0)
                  ? <>
                        <Space>
                            <Button style={{
                              color: '#d67632'
                            }} loading={loading} type="link" onClick={handleAssignSelectedUser}>Assign</Button>
                        </Space>
                    </>
                  : null
            } */}
      <Table
        dataSource={reportUsers}
        columns={userReportsColumns}
        rowKey="sub"
        bordered
        loading={loading}
        pagination={{ pageSize: 10, responsive: true }}
        scroll={{ x: 'max-content' }}
      />
    </Modal>
  )
}

export default ReportUsersModal
