const trimWhitespace = (e: any) => {
  // Check if the event has a target (it's an input event)
  if (e?.target) {
    // If it's a string, trim the whitespace; otherwise, return the value as is
    return typeof e.target.value === 'string' ? e.target.value.trim() : e.target.value
  }
  // If it's not an input event, return the value as is
  return e
}

export { trimWhitespace }
