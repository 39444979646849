import React, { createContext, useContext, useEffect, useReducer } from 'react'
import type {
  AdminsReportContextProps,
  ReportProviderProps,
  AdminsReportsState
} from 'types'

import { UserContext } from './UserContext'
import { listAdminReport } from 'services'
import { adminsReportReducer } from 'reducers'
import { ADMINS_REPORTS_ACTION_TYPE_ENUMS } from 'appConstants'

const AdminsReportsContext = createContext<AdminsReportContextProps | undefined>(undefined)

const AdminsReportsProvider: React.FC<ReportProviderProps> = ({ children }) => {
  const userContext = useContext(UserContext)
  const initialState: AdminsReportsState = {
    adminReport: {
      count: 0,
      last_evaluated_key: null,
      reports: []
    }
  }
  const [state, dispatch] = useReducer(adminsReportReducer, initialState)

  useEffect(function () {
    const removeListener = async function () {
      if (userContext) {
        const { state: userState } = userContext
        if (userState?.isAuthenticated) {
          if (userState?.user?.userIsAdmin) {
            const adminReport = await listAdminReport()
            dispatch({ type: ADMINS_REPORTS_ACTION_TYPE_ENUMS.LIST_ADMIN_REPORTS, payload: adminReport })
          }
        }
      }
    }

    void removeListener()
  }, [dispatch, userContext])

  return (
    <AdminsReportsContext.Provider value={{ state, dispatch }}>
      {children}
    </AdminsReportsContext.Provider>
  )
}

export { AdminsReportsContext, AdminsReportsProvider }
