import React, { useEffect, useRef, useState } from 'react'
import type { CLIENT_REPORTS, COGNITO_GRP_DT, COGNITO_GRP_MEMBER_DT } from 'types'
import { PageTitle } from 'components'
import { useNotifications } from 'hooks'
import Highlighter from 'react-highlight-words'
import { Button, Checkbox, Input, Space, Table, Spin, notification, type InputRef, message, Modal } from 'antd'
import type { FilterConfirmProps } from 'antd/es/table/interface'
import type { ColumnType, ColumnsType } from 'antd/es/table'
import { listCognitoUserGroups, deleteSelectedUserGroups, adminListGroupReportsReport, listCognitoGroupMembers } from 'services'
import { OrderedListOutlined, SearchOutlined, FilterOutlined, ClearOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { type CheckboxChangeEvent } from 'antd/es/checkbox'
import GroupReportsModal from '../Modals/GroupReportsModal'
import AssignReportsFromListGroupsModal from '../Modals/AssignReportsFromListGroupsModal'
import AddUsersToListUserGroupModal from '../Modals/AddUsersToListUserGroupModal'
import { DeleteForeverOutlined, GroupOutlined } from '@mui/icons-material'
import GroupMembershipModal from '../Modals/GroupMembershipModal'

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
interface DataType {
  key: string
  group_name: string
  group_desc: string
  reports: string
  sorter?: (a: any, b: any) => number
  responsive?: Breakpoint[]
  render?: (text: any, report: any) => JSX.Element
}
type DataIndex = keyof DataType

const ListCognitoGroups: React.FC = () => {
  const { addNotification } = useNotifications()
  const [loading, setLoading] = useState(false)
  const [viewCognitoGM, setViewCognitoGM] = useState(false)
  const [groups, setGroups] = useState<COGNITO_GRP_DT[]>([])
  const [cognitoGroupMembers, setCognitoGroupMembers] = useState<COGNITO_GRP_MEMBER_DT[]>([])
  const [view, setView] = useState(false)
  const [viewAddUsersToUserGroupsFromListUserGroups, setViewAddUsersToUserGroupsFromListUserGroups] = useState(false)
  const [viewAssignReportsFromListUserGroups, setViewAssignReportsFromListUserGroups] = useState(false)
  const [currentReport, setCurrentReport] = useState<CLIENT_REPORTS | null>(null)
  const [loadingStates, setLoadingStates] = useState<Record<string, boolean>>({})
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef<InputRef>(null)
  const [selectedUserGroups, setSelectedUserGroups] = useState<COGNITO_GRP_DT[]>([])
  const [selectAll, setSelectAll] = useState(false)

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedUserGroups([])
    } else {
      setSelectedUserGroups(groups)
    }
    setSelectAll(!selectAll)
  }

  useEffect(() => {
    if (selectedUserGroups.length === groups.length) {
      setSelectAll(true)
    } else if (selectAll) {
      setSelectAll(false)
    }
  }, [selectedUserGroups, groups, selectAll])

  const handleSelect = (e: CheckboxChangeEvent, selectedUserGroup: COGNITO_GRP_DT) => {
    if (e.target.checked) {
      setSelectedUserGroups(prev => [...prev, selectedUserGroup])
    } else {
      setSelectedUserGroups(prev => prev.filter(userGroup => userGroup.group_name !== selectedUserGroup.group_name))
    }
  }
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setSearchText('')
  }

  async function handleAssignReportToSelectedUserGroups (): Promise<void> {
    if (selectedUserGroups.length <= 0) {
      void message.error('Select user group to proceed')
    } else {
      try {
        setLoading(true)
        setViewAssignReportsFromListUserGroups(true)
      } catch (error: any) {
        addNotification({
          id: Date.now(),
          type: 'error',
          message: `${error}`,
          timestamp: Date.now()
        })
        void message.error(error?.message ?? 'There was an error assigning the selected user groups to the reports')
      } finally {
        setLoading(false)
      }
    }
  }

  async function handleAddUsersToSelectedUserGroups (): Promise<void> {
    if (selectedUserGroups.length <= 0) {
      void message.error('Select user group to proceed')
    } else {
      try {
        setLoading(true)
        setViewAddUsersToUserGroupsFromListUserGroups(true)
      } catch (error: any) {
        addNotification({
          id: Date.now(),
          type: 'error',
          message: `${error}`,
          timestamp: Date.now()
        })
        void message.error(error?.message ?? 'There was an error adding users to the selected groups')
      } finally {
        setLoading(false)
      }
    }
  }

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<COGNITO_GRP_DT> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => { e.stopPropagation() }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => { setSelectedKeys(e.target.value ? [e.target.value] : []) }}
                    onPressEnter={() => { handleSearch(selectedKeys as string[], confirm, dataIndex) }}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        className='button'
                        type="primary"
                        onClick={() => { handleSearch(selectedKeys as string[], confirm, dataIndex) }}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        className='button'
                        type="primary"
                        onClick={() => { clearFilters && handleReset(clearFilters) }}
                        icon={<ClearOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        icon={<FilterOutlined />}
                        style={{ color: '#d67632' }}
                        onClick={() => {
                          confirm({ closeDropdown: false })
                          setSearchText((selectedKeys as string[])[0])
                          setSearchedColumn(dataIndex)
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        icon={<CloseCircleOutlined />}
                        style={{ color: '#d67632' }}
                        onClick={() => {
                          close()
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
    ),
    filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#d67632' : undefined }} />
    ),
    onFilter: (value, record: any) => {
      return (record && dataIndex && record[dataIndex])
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase())
        : false
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex
        ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
          )
        : (
            text
          )
  })

  const columns: ColumnsType<COGNITO_GRP_DT> = [
    {
      title: <Checkbox checked={selectAll} onChange={handleSelectAll} />,
      dataIndex: 'select',
      render: (_: any, record: COGNITO_GRP_DT) => (
                <Checkbox
                    checked={selectedUserGroups.includes(record)}
                    onChange={(e) => { handleSelect(e, record) }}
                />
      )
    },
    {
      title: 'Group Name',
      dataIndex: 'group_name',
      key: 'group_name',
      sorter: (a: any, b: any) => a.group_name.localeCompare(b.group_name),
      ...getColumnSearchProps('group_name')
    },
    {
      title: 'Description',
      dataIndex: 'group_desc',
      key: 'group_desc',
      responsive: ['lg'],
      sorter: (a: any, b: any) => a.group_desc.localeCompare(b.group_desc),
      ...getColumnSearchProps('group_desc')
    },
    {
      title: 'Members',
      key: 'members',
      render: (text: any, group: COGNITO_GRP_DT) => (
                <Button
                    icon={<GroupOutlined />}
                    loading={loadingStates[`${group.group_name}_members`]}
                    onClick={async () => {
                      try {
                        setLoadingStates(prev => ({ ...prev, [`${group.group_name}_members`]: true }))
                        const params = {
                          group_name: group.group_name
                        }
                        const cognitoGroupMembers = await listCognitoGroupMembers(params)
                        setCognitoGroupMembers(cognitoGroupMembers)
                        setViewCognitoGM(true)
                      } catch (error: any) {
                        addNotification({
                          id: Date.now(),
                          type: 'error',
                          message: `${error}`,
                          timestamp: Date.now()
                        })
                        notification.error({
                          message: error?.message ?? `An error occurred trying to get members for ${group.group_name}`
                        })
                        console.log(error)
                      } finally {
                        setLoadingStates(prev => ({ ...prev, [`${group.group_name}_members`]: false }))
                      }
                    }}

                />
      )
    },
    {
      title: 'Reports',
      key: 'reports',
      render: (text: any, group: COGNITO_GRP_DT) => (
                <Button
                    icon={<OrderedListOutlined />}
                    loading={loadingStates[`${group.group_name}_reports`]}
                    onClick={async () => {
                      try {
                        setLoadingStates(prev => ({ ...prev, [`${group.group_name}_reports`]: true }))
                        const params = {
                          user_group_id: group.group_name
                        }
                        const userReports = await adminListGroupReportsReport(params)
                        setCurrentReport(userReports)
                        setView(true)
                      } catch (error: any) {
                        addNotification({
                          id: Date.now(),
                          type: 'error',
                          message: `${error}`,
                          timestamp: Date.now()
                        })
                        notification.error({
                          message: error?.message ?? `An error occurred trying to get reports for ${group.group_name}`
                        })
                        console.log(error)
                      } finally {
                        setLoadingStates(prev => ({ ...prev, [`${group.group_name}_reports`]: false }))
                      }
                    }}

                />
      )
    },
    {
      title: 'Delete',
      key: 'delete',
      render: (text: any, group: COGNITO_GRP_DT) => (
                <Button
                    danger
                    disabled={group.group_name === 'admins' || group.group_name === 'clients' }
                    icon={<DeleteForeverOutlined />}
                    loading={loadingStates[`${group.group_name}_delete`]}
                    onClick={async () => {
                      Modal.confirm({
                        title: 'Do you really want to delete this group?',
                        content: `Group Name: ${group.group_name}`,
                        onOk: async () => {
                          try {
                            setLoadingStates(prev => ({ ...prev, [`${group.group_name}_delete`]: true }))
                            const msg = await deleteSelectedUserGroups([group])
                            const listOfGroups = await listCognitoUserGroups()
                            setGroups(listOfGroups)
                            addNotification({
                              id: Date.now(),
                              type: 'info',
                              message: `${msg}`,
                              timestamp: Date.now()
                            })
                            void message.info(msg ?? `Group ${group.group_name} has been deleted successfully.`)
                          } catch (error: any) {
                            addNotification({
                              id: Date.now(),
                              type: 'error',
                              message: `${error}`,
                              timestamp: Date.now()
                            })
                            void message.error(error?.message ?? 'Delete group failed')
                          } finally {
                            setLoadingStates(prev => ({ ...prev, [`${group.group_name}_delete`]: false }))
                          }
                        },
                        okText: 'Yes',
                        cancelText: 'No, Go Back'
                      })
                    }}

                />
      )
    }
  ]

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const listOfGroups = await listCognitoUserGroups()
        setGroups(listOfGroups)
      } catch (error) {
        try {
          addNotification({
            id: Date.now(),
            type: 'error',
            message: `${error}`,
            timestamp: Date.now()
          })
        } catch (innerError) {
          console.log(innerError)
        }
        notification.error({
          message: 'Error getting user groups',
          description: `${error}.`
        })
      } finally {
        setLoading(false)
      }
    }

    void fetchData()
  }, [])

  return (
        <div>
            <PageTitle text="User Groups" />
            {
                (selectedUserGroups && selectedUserGroups.length > 0)
                  ? <>
                        <Space>
                            {/* <Button type="link" danger onClick={handleDeleteSelectedUserGroups}>Delete</Button> */}
                            <Button type="link" style={{
                              color: '#d67632'
                            }} onClick={handleAssignReportToSelectedUserGroups}>Assign Reports</Button>
                            <Button type="link" style={{
                              color: '#d67632'
                            }} onClick={handleAddUsersToSelectedUserGroups}>Add Users</Button>
                        </Space>
                    </>
                  : null
            }
            {loading
              ? (
                    <Spin />
                )
              : (
                    <>
                    <GroupMembershipModal
                      visible={viewCognitoGM}
                      groupMembers={cognitoGroupMembers}
                      onClose={() => {
                        setViewCognitoGM(false)
                      }} />
                    <AddUsersToListUserGroupModal
                           visible={viewAddUsersToUserGroupsFromListUserGroups}
                           groups={selectedUserGroups}
                           onClose={() => {
                             setViewAddUsersToUserGroupsFromListUserGroups(false)
                           }} />
                    <AssignReportsFromListGroupsModal
                           visible={viewAssignReportsFromListUserGroups}
                           groups={selectedUserGroups}
                           onClose={() => {
                             setViewAssignReportsFromListUserGroups(false)
                           }} />
                        <GroupReportsModal
                            visible={view}
                            reports={currentReport?.reports ?? []}
                            onClose={() => {
                              setView(false)
                              setCurrentReport(null)
                            }}
                        />
                        <Table dataSource={groups} bordered columns={columns} rowKey="id" /></>
                )}
        </div>
  )
}

export default ListCognitoGroups
