import { useState, useEffect, useRef, useCallback } from 'react'
import { type EmbeddingContext, createEmbeddingContext } from 'amazon-quicksight-embedding-sdk'
import { useParams, useNavigate } from 'react-router-dom'
import { Spin, message, Layout } from 'antd'
import { getEmbeddedReport } from 'services'
import ClientNavbar from './ClientNavbar'
import { useNotifications } from 'hooks'
import { checkUrlExpiration } from 'utils'

function ViewReport (): JSX.Element {
  const [selectedKey, setSelectedKey] = useState<string>('listClientReports')
  const navigate = useNavigate()
  const { addNotification } = useNotifications()
  const [loading, setLoading] = useState(false)
  const dashboardContainerRef = useRef<HTMLDivElement>(null)
  const [dashboardUrl, setDashboardUrl] = useState<string>()
  const [isComponentMounted, setIsComponentMounted] = useState(false)
  const [embeddingContext, setEmbeddingContext] = useState<EmbeddingContext>()
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { report_id } = useParams()

  const getEmbedUrl = useCallback(async () => {
    try {
      setLoading(true)
      const params = { report_id }
      const embedUrl = await getEmbeddedReport(params)
      setDashboardUrl(embedUrl)
      const embeddingContext = await createEmbeddingContext()
      setEmbeddingContext(embeddingContext)
    } catch (error: any) {
      addNotification({
        id: Date.now(),
        type: 'error',
        message: `${error}`,
        timestamp: Date.now()
      })
      void message.error(error.message || 'There was an error getting the reports.')
    } finally {
      setLoading(false)
    }
  }, [report_id])

  const embedReport = useCallback(async () => {
    if (!embeddingContext || !dashboardContainerRef.current || !dashboardUrl) {
      console.log('Waiting for embeddingContext, dashboard container and URL...')
      return
    }
    try {
      setLoading(true)

      const frameOptions = {
        url: dashboardUrl,
        container: dashboardContainerRef.current,
        onChange: (changeEvent: any, metadata: any) => {
          switch (changeEvent.eventName) {
            case 'FRAME_MOUNTED': {
              console.log('Do something when the experience frame is mounted.')
              break
            }
            case 'FRAME_LOADED': {
              console.log('Do something when the experience frame is loaded.')
              break
            }
            default: {
              console.log('Unknown change event:', changeEvent, 'metadata', metadata)
            }
          }
        }
      }

      const contentOptions = {
        locale: 'en-US',
        toolbarOptions: {
          export: false,
          undoRedo: false,
          reset: false
        },
        attributionOptions: {
          overlayContent: false
        },
        onMessage: async (messageEvent: any, experienceMetadata: any) => {
          switch (messageEvent.eventName) {
            case 'CONTENT_LOADED': {
              console.log('All visuals are loaded. The title of the document:', messageEvent.message, 'experienceMetadata', experienceMetadata)
              break
            }
            case 'ERROR_OCCURRED': {
              console.log('Error occurred while rendering the experience. Error code:', messageEvent.message.errorCode, 'experienceMetadata', experienceMetadata)
              break
            }
            case 'PARAMETERS_CHANGED': {
              console.log('Parameters changed. Changed parameters:', messageEvent.message, 'experienceMetadata', experienceMetadata)
              break
            }
            case 'SELECTED_SHEET_CHANGED': {
              console.log('Selected sheet changed. Selected sheet:', messageEvent.message, 'experienceMetadata', experienceMetadata)
              break
            }
            case 'SIZE_CHANGED': {
              console.log('Size changed. New dimensions:', messageEvent.message, 'experienceMetadata', experienceMetadata)
              break
            }
            case 'MODAL_OPENED': {
              console.log('MODAL_OPENED:', messageEvent.message, 'experienceMetadata', experienceMetadata)
              window.scrollTo({
                top: 0 // iframe top position
              })
              break
            }
            default: {
              console.log('Unknown message:', messageEvent, 'experienceMetadata', experienceMetadata)
            }
          }
        }
      }
      await embeddingContext.embedDashboard(frameOptions, contentOptions)
    } catch (error: any) {
      addNotification({
        id: Date.now(),
        type: 'error',
        message: `${error}`,
        timestamp: Date.now()
      })
      void message.error(error.message || 'There was an error embedding the report.')
    } finally {
      setLoading(false)
    }
  }, [dashboardContainerRef, dashboardUrl, addNotification])

  useEffect(() => {
    setIsComponentMounted(true)
    void getEmbedUrl()
  }, [getEmbedUrl])

  useEffect(() => {
    if (isComponentMounted && dashboardUrl) {
      void embedReport()
    }
  }, [embedReport, isComponentMounted, dashboardUrl])

  const onMenuClick = (key: string) => {
    navigate('/dashboards')
  }

  return (
    <Layout className="client-layout cardsBody">
      <ClientNavbar onMenuClick={onMenuClick} selectedKey={selectedKey} />
      {loading
        ? (
        <Spin style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }} />
          )
        : (
        <div ref={dashboardContainerRef} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }} />
          )}
    </Layout>
  )
}

export { ViewReport }
