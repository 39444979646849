import { appStateEnums } from 'appConstants'
import type { AppStates, AppAction } from 'types'

export const appReducer = (state: AppStates, action: AppAction): AppStates => {
  console.log('This is the appReducer state', state, action)
  switch (action.type) {
    case appStateEnums.INIT_STATES:
      return {
        ...state,
        theme: action.theme,
        hideImportantMessages: action.hideImportantMessages,
        collapsed: action.collapsed
      }
    case appStateEnums.HIDE_IMPORTANT_NOTIFICATIONS:
      return {
        ...state,
        hideImportantMessages: action.hideImportantMessages
      }
    case appStateEnums.COLLAPSE:
      return {
        ...state,
        collapsed: action.collapsed
      }
    case appStateEnums.THEME:
      return {
        ...state,
        theme: action.theme
      }
    default:
      return state
  }
}
