import React, { useState, useEffect } from 'react'
import { Form, Input, Button, Select, message } from 'antd'
import { createUser, listCognitoUserGroups, getOrganizationIDs } from 'services'
import type { COGNITO_GRP_DT, ORG_ID } from 'types'
import { Disclaimer, PageTitle } from 'components'
import { useNotifications } from 'hooks'
import { trimWhitespace } from 'utils'
const { Option } = Select
const CreateUser: React.FC = () => {
  const [form] = Form.useForm()
  const { addNotification } = useNotifications()
  const [loading, setLoading] = useState(false)
  const [userGroups, setUserGroups] = useState<COGNITO_GRP_DT[]>([])
  const [organizationID, setOrganizationID] = useState<ORG_ID[]>([])

  async function fetchOrgID (): Promise<void> {
    try {
      const orgIds = await getOrganizationIDs()
      if (Array.isArray(orgIds)) {
        setOrganizationID(orgIds)
      }
      console.log(orgIds)
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    void fetchOrgID()
    const loadDropdownData = async () => {
      try {
        const groups = await listCognitoUserGroups()
        if (Array.isArray(groups)) {
          setUserGroups(groups)
        }
      } catch (error) {
        try {
          addNotification({
            id: Date.now(),
            type: 'error',
            message: `${error}`,
            timestamp: Date.now()
          })
        } catch (innerError) {
          console.log(innerError)
        }
      }
    }
    void loadDropdownData()
  }, [])
  const onFinish = async (values: any) => {
    setLoading(true)
    try {
      const userGroupName: string = (JSON.parse(values.user_group)).group_name
      const payload = {
        ...values,
        group_name: userGroupName
      }
      await createUser(payload)
      void message.success(`${values.given_name} ${values.family_name} has been registered successfully with a temporary password.`)
      form.resetFields()
    } catch (error: any) {
      try {
        addNotification({
          id: Date.now(),
          type: 'error',
          message: `${error}`,
          timestamp: Date.now()
        })
      } catch (innerError) {
        console.log(innerError)
      }
      void message.error(error || 'There was an error registering the user.')
    } finally {
      setLoading(false)
    }
  }

  return (
        <div>
            <PageTitle text="Create User" />
            <Form
                form={form}
                name="create-user"
                onFinish={onFinish}
                layout="vertical"
                style={{ maxWidth: '500px' }}
            >
                          <Disclaimer
                title="⚠️ Important Note"
                message="Password will be autogenerated! Inform the client to check their spam folder in case they did not get the notification in their inbox."
            />
                <Form.Item
                    label="First Name"
                    name="given_name"
                    getValueFromEvent={trimWhitespace}
                    rules={[{ required: true, message: 'Please input the username!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Last Name"
                    name="family_name"
                    getValueFromEvent={trimWhitespace}
                    rules={[{ required: true, message: 'Please input the username!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    getValueFromEvent={trimWhitespace}
                    rules={[
                      { required: true, message: 'Please input the email!' },
                      { type: 'email', message: 'The input is not valid E-mail!' }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Select a Users Group"
                    name="user_group"
                    rules={[{ required: true, message: 'Please select a users group!' }]}
                >
                    <Select showSearch placeholder="Select a users group">
                        {userGroups.map(group => <Option key={group.group_name} value={JSON.stringify(group)}>{`${group.group_name} | ${group.group_desc}`}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Select organization id"
                    name="org_id"
                    rules={[{ required: true, message: 'Please select a users group!' }]}
                >
                    <Select showSearch placeholder="Select organization id">
                      {organizationID.map(item => <Option key={item.propman_id} value={item.propman_id}>
                        <small>
                         {item.status === 'Active' ? `${item.propman_id} | ${item.organization} | ${item.status}` : null}
                        </small>
                      </Option>)}
                      </Select>
                </Form.Item>
                <Form.Item>
                    <Button className='button' type="primary" htmlType="submit" loading={loading}>
                        Create
                    </Button>
                </Form.Item>
            </Form>
        </div>
  )
}

export default CreateUser
