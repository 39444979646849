import { useState } from 'react'
import { type NOTIFICATION_DT } from 'types'
// import { notification as AntNotification } from 'antd'

const NOTIFICATION_KEY = 'admin_notifications'

export const useNotifications = () => {
  const [notifications, setNotifications] = useState<NOTIFICATION_DT[]>(() => {
    const storedNotifications = localStorage.getItem(NOTIFICATION_KEY)
    return storedNotifications ? JSON.parse(storedNotifications) : []
  })

  const addNotification = (notif: NOTIFICATION_DT) => {
    const updatedNotifications = [...notifications, notif]
    setNotifications(updatedNotifications)
    localStorage.setItem(NOTIFICATION_KEY, JSON.stringify(updatedNotifications))
    // if (typeof AntNotification[notif.type] === 'function') {
    //   (AntNotification[notif.type] as (args: any) => void)({ message: notif.message })
    // } else {
    //   console.error(`Notification type ${notif.type} is not supported.`)
    // }
  }

  const clearSelectedNotifications = (ids?: number[]) => {
    let updatedNotifications = notifications
    if (ids && ids.length > 0) {
      updatedNotifications = notifications.filter(n => !ids.includes(n.id))
    }
    setNotifications(updatedNotifications)
    localStorage.setItem(NOTIFICATION_KEY, JSON.stringify(updatedNotifications))
  }
  const clearAllNotifications = () => {
    let updatedNotifications = notifications
    updatedNotifications = []
    setNotifications(updatedNotifications)
    localStorage.setItem(NOTIFICATION_KEY, JSON.stringify(updatedNotifications))
  }
  return {
    notifications,
    addNotification,
    clearSelectedNotifications,
    clearAllNotifications
  }
}
