// ListUsers.tsx
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { deleteReports, listAdminReport, getOrganizationIDs } from 'services'
import { type ORG_ID, type ADMIN_REPORT } from 'types'
import {
  EyeOutlined,
  AlertOutlined,
  SearchOutlined,
  FilterOutlined,
  ClearOutlined,
  CloseCircleOutlined,
  OrderedListOutlined
} from '@ant-design/icons'
import { ViewAdminsReport } from './ViewAdminsReport'
import { PageTitle } from 'components'
import { useNotifications } from 'hooks'
import Highlighter from 'react-highlight-words'
import { Input, Button, Select, message, Checkbox, Descriptions, Modal, Space, Table, Spin, notification, type InputRef } from 'antd'
import type { FilterConfirmProps } from 'antd/es/table/interface'
import type { ColumnType, ColumnsType } from 'antd/es/table'
import { type CheckboxChangeEvent } from 'antd/es/checkbox'
import { DeleteForeverOutlined } from '@mui/icons-material'
import UserGroupAssignmentModal from '../Modals/UserGroupAssignmentModal'
import UserAssignmentModal from '../Modals/UserAssignmentModal'
import ReportUsersModal from '../Modals/ReportUsersModal'
const { Option } = Select
type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
interface DataType {
  key: string
  report_name: string
  report_desc: string
  details: any
  view: any
  in_use: string
  delete: any
  sorter?: (a: any, b: any) => number
  responsive?: Breakpoint[]
  render?: (text: any, report: any) => JSX.Element
}
type DataIndex = keyof DataType

const ListAdminReports: React.FC = () => {
  const { addNotification } = useNotifications()
  const [loading, setLoading] = useState(false)
  const [reports, setReports] = useState<ADMIN_REPORT[]>([])
  const [visible, setVisible] = useState(false)
  const [view, setView] = useState(false)
  const [viewUsers, setViewUsers] = useState(false)
  const [viewSelectedForAssignUser, setViewSelectedForAssignUser] = useState(false)
  const [viewSelectedForAssignUserGroup, setViewSelectedForAssignUserGroup] = useState(false)
  const [currentReport, setCurrentReport] = useState<any | null>(null)
  const [selectedAdminReports, setSelectedAdminReports] = useState<ADMIN_REPORT[]>([])
  const [selectedAdminReport, setSelectedAdminReport] = useState<ADMIN_REPORT | null>()
  const [loadingStates, setLoadingStates] = useState<Record<string, boolean>>({})
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef<InputRef>(null)
  const [selectAll, setSelectAll] = useState(false)
  const [organizationID, setOrganizationID] = useState<ORG_ID[]>([])

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedAdminReports([])
    } else {
      setSelectedAdminReports(reports)
    }
    setSelectAll(!selectAll)
  }

  useEffect(() => {
    if (selectedAdminReports.length === reports.length) {
      setSelectAll(true)
    } else if (selectAll) {
      setSelectAll(false)
    }
  }, [selectedAdminReports, reports, selectAll])

  const handleSelect = (e: CheckboxChangeEvent, selectedAdminReport: ADMIN_REPORT) => {
    if (e.target.checked) {
      setSelectedAdminReports(prev => [...prev, selectedAdminReport])
    } else {
      setSelectedAdminReports(prev => prev.filter(report => report.report_id !== selectedAdminReport.report_id))
    }
  }
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setSearchText('')
  }
  async function handleDeleteSelectedReports (): Promise<void> {
    if (selectedAdminReports.length <= 0) {
      void message.error('Select reports to delete')
    } else {
      try {
        setLoading(true)
        const msg = await deleteReports(selectedAdminReports)
        addNotification({
          id: Date.now(),
          type: 'info',
          message: `${msg}`,
          timestamp: Date.now()
        })
        await refreshData()
        void message.info(msg)
      } catch (error: any) {
        addNotification({
          id: Date.now(),
          type: 'error',
          message: `${error}`,
          timestamp: Date.now()
        })
        void message.error(error?.message ?? 'There was an error deleting the selected reports')
      } finally {
        setLoading(false)
      }
    }
  }

  async function handleAssignUsers (): Promise<void> {
    if (selectedAdminReports.length <= 0) {
      void message.error('Select reports to assign to a user')
    } else {
      try {
        setLoading(true)
        setViewSelectedForAssignUser(true)
      } catch (error: any) {
        addNotification({
          id: Date.now(),
          type: 'error',
          message: `${error}`,
          timestamp: Date.now()
        })
        void message.error(error?.message ?? 'There was an error assigning the selected reports to the users')
      } finally {
        setLoading(false)
      }
    }
  }

  async function handleAssignUserGroups (): Promise<void> {
    if (selectedAdminReports.length <= 0) {
      void message.error('Select reports to assign to user groups')
    } else {
      try {
        setLoading(true)
        setViewSelectedForAssignUserGroup(true)
      } catch (error: any) {
        addNotification({
          id: Date.now(),
          type: 'error',
          message: `${error}`,
          timestamp: Date.now()
        })
        void message.error(error?.message ?? 'There was an error assigning the selected reports to the user groups')
      } finally {
        setLoading(false)
      }
    }
  }

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<ADMIN_REPORT> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => { e.stopPropagation() }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => { setSelectedKeys(e.target.value ? [e.target.value] : []) }}
                    onPressEnter={() => { handleSearch(selectedKeys as string[], confirm, dataIndex) }}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        className='button'
                        type="primary"
                        onClick={() => { handleSearch(selectedKeys as string[], confirm, dataIndex) }}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        className='button'
                        type="primary"
                        onClick={() => { clearFilters && handleReset(clearFilters) }}
                        icon={<ClearOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        icon={<FilterOutlined />}
                        style={{ color: '#d67632' }}
                        onClick={() => {
                          confirm({ closeDropdown: false })
                          setSearchText((selectedKeys as string[])[0])
                          setSearchedColumn(dataIndex)
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        icon={<CloseCircleOutlined />}
                        style={{ color: '#d67632' }}
                        onClick={() => {
                          close()
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
    ),
    filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#d67632' : undefined }} />
    ),
    onFilter: (value, record: any) => {
      return (record && dataIndex && record[dataIndex])
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase())
        : false
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex
        ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
          )
        : (
            text
          )
  })

  const columns: ColumnsType<ADMIN_REPORT> = [
    {
      title: <Checkbox checked={selectAll} onChange={handleSelectAll} />,
      dataIndex: 'select',
      render: (_: any, record: ADMIN_REPORT) => (
                <Checkbox
                    checked={selectedAdminReports.includes(record)}
                    onChange={(e) => { handleSelect(e, record) }}
                />
      )
    },
    {
      title: 'Name',
      dataIndex: 'report_name',
      key: 'report_name',
      sorter: (a: any, b: any) => a.report_name.localeCompare(b.report_name),
      ...getColumnSearchProps('report_name')
    },
    {
      title: 'Description',
      dataIndex: 'report_desc',
      key: 'report_desc',
      responsive: ['lg'],
      sorter: (a: any, b: any) => a.report_desc.localeCompare(b.report_desc),
      ...getColumnSearchProps('report_desc')
    },
    {
      title: 'Details',
      key: 'details',
      render: (text: any, report: any) => (
                <Button className='button'
                    icon={<AlertOutlined />}
                    loading={loading}
                    onClick={async () => {
                      setCurrentReport(report)
                      setVisible(true)
                    }}
                />
      )
    },
    {
      title: 'View',
      key: 'view',
      render: (text: any, report: any) => (
                <Button className='button'
                    icon={<EyeOutlined />}
                    loading={loading}
                    onClick={async () => {
                      setCurrentReport(report)
                      setView(true)
                    }}
                />
      )
    },
    {
      title: 'In Use',
      dataIndex: 'in_use',
      key: 'in_use',
      responsive: ['lg'],
      sorter: (a: any, b: any) => a.in_use.localeCompare(b.in_use),
      ...getColumnSearchProps('in_use')
    },
    {
      title: 'Users',
      key: 'users',
      render: (text: any, report: any) => (
        <Button disabled={report.in_use === 'No'}
            icon={<OrderedListOutlined />}
            loading={loading}
            onClick={async () => {
              setSelectedAdminReport(report)
              setViewUsers(true)
            }}
        />
      )
    },
    {
      title: 'Organization ID',
      dataIndex: 'propman_id',
      key: 'propman_id'
    }
    // ,
    // {
    //   title: 'Delete',
    //   key: 'delete',
    //   render: (text: any, report: ADMIN_REPORT) => (
    //             <Button
    //                 danger
    //                 disabled={report.in_use === 'Yes'}
    //                 icon={<DeleteForeverOutlined />}
    //                 loading={loadingStates[report.report_id]}
    //                 onClick={async () => {
    //                   Modal.confirm({
    //                     title: 'Do you really want to delete this report?',
    //                     content: `Report Name: ${report?.report_name}`,
    //                     onOk: async () => {
    //                       try {
    //                         setLoadingStates(prev => ({ ...prev, [report.report_id]: true }))
    //                         await deleteReports([report])
    //                         await refreshData()
    //                         showNotification('success', 'Report Deleted Successfully', `Report ${report?.report_name} has been deleted successfully.`)
    //                       } catch (error: any) {
    //                         addNotification({
    //                           id: Date.now(),
    //                           type: 'error',
    //                           message: `${error}`,
    //                           timestamp: Date.now()
    //                         })
    //                         showNotification('error', 'Delete Report Failed', error.message || 'There was an error deleting the report.')
    //                       } finally {
    //                         setLoadingStates(prev => ({ ...prev, [report.report_id]: false }))
    //                       }
    //                     },
    //                     okText: 'Yes',
    //                     cancelText: 'No, Go Back'
    //                   })
    //                 }}

    //             />
    //   )
    // }
  ]

  const showNotification = useCallback((type: keyof typeof notification, message: string, description: string) => {
    if (type in notification) {
      notification[type]({
        message,
        description
      } as any)
    } else {
      console.error(`Notification type ${type} does not exist.`)
    }
  }, [])

  const refreshData = useCallback(async () => {
    try {
      const fetchedReport = await listAdminReport()
      setReports(fetchedReport.reports)
    } catch (error) {
      try {
        addNotification({
          id: Date.now(),
          type: 'error',
          message: `${error}`,
          timestamp: Date.now()
        })
      } catch (innerError) {
        console.log(innerError)
      }
      showNotification('error', 'Error getting reports', `${error}`)
    }
  }, [showNotification])

  async function fetchOrgID () {
    try {
      const orgIds = await getOrganizationIDs()
      if (Array.isArray(orgIds)) {
        setOrganizationID(orgIds)
      }
      console.log(orgIds)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    void fetchOrgID()
    void refreshData()
  }, [refreshData])

  useEffect(() => {
    setLoading(true)

    refreshData()
      .then(fetchedReport => {
        console.log(fetchedReport)
      })
      .catch(err => {
        console.error('Error fetching the reports', err)
        showNotification('error', 'Error getting reports', `${err}`)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [refreshData, showNotification])

  return (
        <div>
            <PageTitle text="Available Quicksight Reports" />
            {
                (selectedAdminReports && selectedAdminReports.length > 0)
                  ? <>
                        <Space>
                            <Button type="link" danger onClick={handleDeleteSelectedReports}>Delete</Button>
                            <Button type="link" style={{
                              color: '#d67632'
                            }} onClick={handleAssignUsers}>Assign Users</Button>
                            <Button type="link" style={{
                              color: '#d67632'
                            }} onClick={handleAssignUserGroups}>Assign Groups</Button>
                        </Space>
                    </>
                  : null
            }
            {loading
              ? (<Spin />)
              : (<Table bordered dataSource={reports ?? []} columns={columns} rowKey="report_id" />)
            }
            <UserAssignmentModal
                visible={viewSelectedForAssignUser}
                reports={selectedAdminReports}
                onClose={() => {
                  setViewSelectedForAssignUser(false)
                }} />
            {selectedAdminReport && <ReportUsersModal
                visible={viewUsers}
                report={selectedAdminReport}
                onClose={() => {
                  setViewUsers(false)
                  setSelectedAdminReport(null)
                }} />}
            <UserGroupAssignmentModal
                visible={viewSelectedForAssignUserGroup}
                reports={selectedAdminReports}
                onClose={() => {
                  setViewSelectedForAssignUserGroup(false)
                }} />
            {currentReport && (
              <Modal
              open={visible}
              onCancel={() => { setVisible(false) }}
              footer={null}
          >
              <PageTitle text={currentReport.report_name} />
              <Descriptions bordered column={1}>
                  <Descriptions.Item label="Report Name">{currentReport.report_name}</Descriptions.Item>
                  <Descriptions.Item label="Description">{currentReport.report_desc}</Descriptions.Item>
                  <Descriptions.Item label="Report ID">{currentReport.report_id}</Descriptions.Item>
                  {/* <Descriptions.Item label="Dataset ID">{currentReport.dataset_id}</Descriptions.Item>
                  <Descriptions.Item label="Workspace ID">{currentReport.workspace_id}</Descriptions.Item> */}
                  <Descriptions.Item label="In Use">{currentReport.in_use}</Descriptions.Item>
              </Descriptions>
          </Modal>
            )}
            {currentReport && (
                <Modal
                    open={view}
                    onCancel={() => {
                      setView(false)
                      setCurrentReport(null)
                    }}
                    footer={null}
                    width={'80%'}
                >
                    <PageTitle text={`You are viewing in admin mode: ${currentReport.report_name}`} />
                    <ViewAdminsReport reportId={currentReport.report_id} />
                </Modal>
            )}
        </div>
  )
}

export default ListAdminReports
