import { Card, Input, Button, notification, Form } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import propman_white_clear from 'assets/logo-with-name.png'
import React, { useState, useEffect, useContext } from 'react'
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'config/constants'
import type { CognitoUser } from 'amazon-cognito-identity-js'
import { USER_ACTION_TYPE_ENUMS } from 'appConstants'
import { getUser } from 'services'
import type { USER_DT } from 'types'
import { UserContext } from 'context'
import { trimWhitespace } from 'utils'
import PleaseWaitOverlay from '../admin-portal/Modals/PleaseWaitOverlay'
import { signOut } from './SignOut'

const LoginPage: React.FC = () => {
  const [error, setError] = useState<string | null>(null)
  const { dispatch } = useContext(UserContext)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [newPasswordRequired, setNewPasswordRequired] = useState(false)

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }
  const letsGo = async function () {
    try {
      setLoading(true)
      const userData: USER_DT = await getUser()
      dispatch({ type: USER_ACTION_TYPE_ENUMS.AUTHENTICATED, user: userData })

      if (!userData?.sub) {
        setLoading(false)
        return
      }
      if (!userData?.email_verified) {
        navigate(ROUTES.VERIFY_EMAIL)
        setLoading(false)
        return
      }
      if (userData?.email_verified && userData?.userIsAdmin) {
        navigate(ROUTES.ADMIN)
        setLoading(false)
      } else if (
        userData?.email_verified &&
            (userData?.userIsAdmin !== undefined || userData?.userIsAdmin !== null) &&
            !userData?.userIsAdmin
      ) {
        navigate(ROUTES.DASHBOARD)
        setLoading(false)
      }
    } catch (err: any) {
      console.log('login page navigation error: ', err)

      // Handle specific errors based on their type or message (e.g., preflight error)
      if (err.message.includes('preflight')) {
        setError('Network issues detected. Please try again later.')
      } else {
        setError('An unexpected error occurred. Please try again later.')
      }

      if (err === 'User not found') {
        try {
          await signOut()
        } catch (error) {
          console.log('signOut error: ', err)
        }
        // return
      }
      // Default behavior in case of unexpected errors
      navigate(ROUTES.LANDING_PAGE)
    } finally {
      setLoading(false)
    }
  }
  useEffect(function () {
    const checkUser = async () => {
      try {
        const authenticated: CognitoUser = await Auth.currentAuthenticatedUser()
        if (authenticated.getUsername()) {
          await letsGo()
        }
      } catch (error) {

      }
    }
    void checkUser()
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const isMobile = windowWidth <= 768

  const responsiveStyles = {
    card: isMobile
      ? {
          width: '90%',
          margin: '50px auto'
        }
      : {
          width: 400,
          margin: '100px auto'
        },
    logo: isMobile
      ? {
          width: '70%'
        }
      : {
          width: 250
        }
  }

  const onFinish = async (values: { username: string, password: string, newPassword?: string }) => {
    try {
      setLoading(true)
      const { username, password, newPassword } = values

      const user = await Auth.signIn(username, password)
      if (user?.challengeName && user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setNewPasswordRequired(true)
        if (newPasswordRequired && newPassword) {
          await Auth.completeNewPassword(user, newPassword)
          // First do a check for success
          await letsGo()
        }
      } else {
        await letsGo()
      }
    } catch (error: any) {
      console.log(error)

      if (error.code === 'NewPasswordRequiredException') {
        setNewPasswordRequired(true)
      } else {
        notification.error({
          message: 'An Error Occurred',
          description: error?.message || 'There was an error logging in.'
        })
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Card className='login-container' style={{ ...responsiveStyles.card, textAlign: 'center' }}>
      <div style={{ marginBottom: 50 }}>
        <img src={propman_white_clear} alt="Logo" style={{ ...responsiveStyles.logo }} />
      </div>
      <Form onFinish={onFinish}>
              <Form.Item name="username" getValueFromEvent={trimWhitespace} rules={[{ required: true, message: 'Please input your username (email)!' }]}>
           <Input className='input-field'
            prefix={<UserOutlined />}
            placeholder="User name (email)"
            style={{ marginBottom: 20 }}
          />
        </Form.Item>
        <Form.Item name="password" getValueFromEvent={trimWhitespace} rules={[{ required: true, message: 'Please input your password!' }]}>
          <Input.Password className='input-field'
            prefix={<LockOutlined />}
            placeholder="Password"
            style={{ marginBottom: 20 }}
          />
        </Form.Item>
        <PleaseWaitOverlay visible={loading} />
        {newPasswordRequired && (
          <Form.Item name="newPassword" getValueFromEvent={trimWhitespace}
          rules={[{ required: true, message: 'Please input your new password!' },
            {
              min: 8,
              message: 'Password must be at least 8 characters long!'
            },
            {
              pattern: /(?=.*[a-z])/,
              message: 'Password must include lowercase letters!'
            },
            {
              pattern: /(?=.*[A-Z])/,
              message: 'Password must include uppercase letters!'
            },
            {
              pattern: /(?=.*\d)/,
              message: 'Password must include digits!'
            },
            {
              pattern: /(?=.*[\W_])/,
              message: 'Password must include symbols!'
            }]}>
            <Input.Password className='input-field'
              prefix={<LockOutlined />}
              placeholder="New Password"
              style={{ marginBottom: 20 }}
            />
          </Form.Item>
        )}

        <Form.Item>
          <Button className='button' block type="primary" htmlType="submit" loading={loading}>
            {newPasswordRequired ? 'Set New Password' : 'Log on'}
          </Button>
        </Form.Item>
      </Form>
      {!newPasswordRequired && (
        <Button className='button' type="primary" block disabled={loading} onClick={() => { navigate(ROUTES.RESET_PASSWORD) }}>
          Reset Password
        </Button>
      )}
    </Card>
  )
}

export { LoginPage }
