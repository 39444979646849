import { AppRoutes } from './routes/Routes'
import './styles/App.css'
import { BrowserRouter as Router } from 'react-router-dom'
import { AppProvider, UserProvider } from 'context'
function App (): any {
  return (
        <Router>
            <AppProvider>
                <UserProvider>
                    <AppRoutes />
                </UserProvider>
            </AppProvider>

        </Router>
  )
}

export default App
