import { Auth } from '@aws-amplify/auth'

const getIdToken = async (): Promise<string> => {
  try {
    const session = await Auth.currentSession()
    const idToken = session.getIdToken().getJwtToken()
    return idToken
  } catch (error) {
    console.error('Error getting the token', error)
    throw error // Re-throwing the error so it can be handled by the caller
  }
}

export { getIdToken }
