import React, { useState } from 'react'
import { Form, Input, Button, notification } from 'antd'
import { createCognitoUserGroup } from 'services'
import type { COGNITO_GRP_DT } from 'types'
import { Disclaimer, PageTitle } from 'components'
import { useNotifications } from 'hooks'
import { trimWhitespace } from 'utils'

const CreateCognitoUserGroup: React.FC = () => {
  const { addNotification } = useNotifications()
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const onFinish = async (values: COGNITO_GRP_DT) => {
    setLoading(true)
    try {
      console.log(values)
      const msg = await createCognitoUserGroup(values)
      notification.success({
        message: 'Group Created Successfully',
        description: msg || `Group ${values?.group_name} has been created successfully.`
      })
      form.resetFields()
    } catch (error: any) {
      try {
        addNotification({
          id: Date.now(),
          type: 'error',
          message: `${error}`,
          timestamp: Date.now()
        })
      } catch (innerError) {
        console.log(innerError)
      }
      notification.error({
        message: 'Group Creation Failed',
        description: error?.message || 'There was an error creating the group.'
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <PageTitle text="Create User Group in AWS Cognito" />
      <Form
        form={form}
        name="create-user-group"
        onFinish={onFinish}
        layout="vertical"
        style={{ maxWidth: '500px' }}
      >
              <Disclaimer
                title="⚠️ Important Note"
                message="This will be used later to group users together for ease of identification, assignments or other managements. Attempt to create a group that already exists will simply result in error"
            />
        <Form.Item
          label="Group Name"
          name="group_name"
          getValueFromEvent={trimWhitespace}
          rules={[
            { required: true, message: 'Please input the group name!' },
            { pattern: /^[A-Za-z0-9_]+$/, message: 'Group name should only contain alphanumeric characters and underscores.' }
          ]}
        >
          <Input
           />
        </Form.Item>
        <Form.Item
          label="Group Description"
          name="group_desc"
          // getValueFromEvent={trimWhitespace}
          rules={[
            { required: true, message: 'Please input the group description!' },
            { pattern: /^[A-Za-z0-9_ ]+$/, message: 'Group description should only contain alphanumeric characters and spaces.' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button className='button' type="primary" htmlType="submit" loading={loading}>
            Create Group
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default CreateCognitoUserGroup
