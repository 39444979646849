import React, { useState, useContext } from 'react'
import { Layout, Menu, Avatar, Button, Dropdown, message } from 'antd'
import { UserOutlined, LogoutOutlined, OrderedListOutlined, MenuOutlined } from '@ant-design/icons'
import { UserContext } from 'context'
import { signOut } from 'components/pages/auth/SignOut'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'config/constants'
import { AdminPanelSettingsOutlined } from '@mui/icons-material'
const { Header } = Layout

interface NavbarProps {
  onMenuClick: (key: string) => void
  selectedKey: string
}

const ClientNavbar: React.FC<NavbarProps> = ({ onMenuClick, selectedKey }) => {
  const navigate = useNavigate()
  const [hoveredKey, setHoveredKey] = useState<string | null>(null)
  const { state } = useContext(UserContext)
  const { user } = state
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

  const menuItemStyle = (key: string) => ({
    backgroundColor: hoveredKey === key ? '#d67632' : 'transparent',
    color: hoveredKey === key ? '#ffffff' : '#d67632',
    fontSize: '1.1em',
    margin: '0 5px',
    borderRadius: '8px',
    transition: 'background-color 0.3s ease',
    lineHeight: '64px'
  })

  const menu = (
    <Menu
      className="menu"
      theme="dark"
      mode="horizontal"
      style={{ backgroundColor: 'transparent', lineHeight: '64px', borderBottom: 'none' }}
      defaultSelectedKeys={[selectedKey]}
    >
   {user?.userIsAdmin && <Menu.Item
        style={menuItemStyle('adminMode')}
        onMouseEnter={() => { setHoveredKey('listClientReports') }}
        onMouseLeave={() => { setHoveredKey(null) }}
        key="adminMode"
        icon={<AdminPanelSettingsOutlined />}
        onClick={() => {
          try {
            navigate(ROUTES.ADMIN)
          } catch (error: any) {
            void message.error(error || 'There was an error getting back to admin mode. Please reload your page')
          }
        }}
      >
        Admin Mode
      </Menu.Item>}
      <Menu.Item
        style={menuItemStyle('listClientReports')}
        onMouseEnter={() => { setHoveredKey('listClientReports') }}
        onMouseLeave={() => { setHoveredKey(null) }}
        key="listClientReports"
        icon={<OrderedListOutlined />}
        onClick={() => { onMenuClick('listClientReports') }}
      >
        My Reports
      </Menu.Item>
      <Menu.Item
        style={menuItemStyle('logOut')}
        onMouseEnter={() => { setHoveredKey('logOut') }}
        onMouseLeave={() => { setHoveredKey(null) }}
        key="logOut"
        icon={<LogoutOutlined />}
        onClick={() => {
          signOut().then(() => {
            console.log('Successfully signed out!')
          }).catch((err) => {
            console.error('Error signing out:', err)
          })
        }}
      >
        Logout
      </Menu.Item>
    </Menu>
  )

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <Header style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 20px',
      backgroundColor: '#5f2d78',
      boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.2)'
    }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <Avatar src={user?.avatarUrl} size={32} icon={!user?.avatarUrl && <UserOutlined />} />
        <span style={{ color: '#ecf0f1', fontSize: '1.1em' }}>{`${user?.given_name} ${user?.family_name}`}</span>
      </div>
      {isMobile
        ? (
          <Dropdown overlay={menu} trigger={['click']}>
            <Button className='button' type="link" icon={<MenuOutlined />} />
          </Dropdown>
          )
        : menu}
    </Header>
  )
}

export default ClientNavbar
