import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Modal, Table, message, Space, notification } from 'antd'
import type { COGNITO_GRP_DT, USER_DT } from 'types'
import { PageTitle } from 'components'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import { useNotifications } from 'hooks'
import { addUsersToCognitoGroups, listCognitoUserGroups } from 'services'
type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

interface GroupColumn {
  key: string
  title: string
  dataIndex: string
  sorter?: (a: any, b: any) => number
  responsive?: Breakpoint[]
  render?: (text: any, report: any) => JSX.Element
}

interface AddUsersToUserGroupModalProps {
  visible: boolean
  users: USER_DT[]
  onClose: () => void
}

const AddSelectedUsersToUserGroupModal: React.FC<AddUsersToUserGroupModalProps> = ({ visible, users, onClose }) => {
  const [loading, setLoading] = useState(false)
  const { addNotification } = useNotifications()
  const [groups, setGroups] = useState<COGNITO_GRP_DT[]>([])
  const [selectedGroups, setSelectedGroups] = useState<COGNITO_GRP_DT[]>([])
  const handleSelect = (e: CheckboxChangeEvent, setSelectedGroup: COGNITO_GRP_DT) => {
    if (e.target.checked) {
      setSelectedGroups(prev => [...prev, setSelectedGroup])
    } else {
      setSelectedGroups(prev => prev.filter(group => group.group_name !== setSelectedGroup.group_name))
    }
  }
  const userReportsColumns: GroupColumn[] = [
    {
      key: 'id',
      title: 'Select',
      dataIndex: 'id',
      render: (_: any, record: any) => <Checkbox style={{
      }} onChange={e => { handleSelect(e, record) }} />
    },
    {
      title: 'Group Name',
      dataIndex: 'group_name',
      key: 'group_name',
      sorter: (a, b) => a.group_name.localeCompare(b.group_name)
    },
    {
      title: 'Description',
      dataIndex: 'group_desc',
      key: 'group_name',
      responsive: ['lg']
    }
  ]

  async function handleAssignSelectedUser (): Promise<void> {
    if (selectedGroups.length <= 0) {
      void message.error('Select groups to users')
    } else {
      try {
        setLoading(true)
        const payload = {
          groups: selectedGroups,
          users
        }
        const msg = await addUsersToCognitoGroups(payload)
        addNotification({
          id: Date.now(),
          type: 'info',
          message: `${msg}`,
          timestamp: Date.now()
        })
        void message.info(msg)
        onClose()
      } catch (error: any) {
        addNotification({
          id: Date.now(),
          type: 'error',
          message: `${error}`,
          timestamp: Date.now()
        })
        void message.error(error?.message ?? 'There was an error adding selected users to the groups')
      } finally {
        setLoading(false)
      }
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const listOfGroups = await listCognitoUserGroups()
        setGroups(listOfGroups)
      } catch (error) {
        console.error('Error fetching the users', error)
        try {
          addNotification({
            id: Date.now(),
            type: 'error',
            message: `${error}`,
            timestamp: Date.now()
          })
        } catch (innerError) {
          console.log(innerError)
        }
        notification.error({
          message: 'Error getting groups',
          description: `${error}.`
        })
      } finally {
        setLoading(false)
      }
    }

    void fetchData()
  }, [])

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
    >
      <PageTitle text="Users" />
      {
                (selectedGroups && selectedGroups.length > 0)
                  ? <>
                        <Space>
                            <Button style={{
                              color: '#d67632'
                            }} loading={loading} type="link" onClick={handleAssignSelectedUser}>Add</Button>
                        </Space>
                    </>
                  : null
            }
      <Table
        dataSource={groups}
        columns={userReportsColumns}
        rowKey="group_name"
        bordered
        pagination={{ pageSize: 10, responsive: true }}
        scroll={{ x: 'max-content' }}
      />
    </Modal>
  )
}

export default AddSelectedUsersToUserGroupModal
