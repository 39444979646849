import React, { useEffect, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { Button, Checkbox, Input, Space, Table, Tag, Spin, notification, type InputRef, message } from 'antd'
import type { FilterConfirmProps } from 'antd/es/table/interface'
import type { ColumnType, ColumnsType } from 'antd/es/table'
import { activateUsers, adminListUserReportsReport, deactivateUsers, deleteSelectedUsers, listCognitoGroupsForAUser, listUsers, listUserOrgs } from 'services'
import type { CLIENT_REPORTS, COGNITO_GRP_DT, ORG_MEMBER_DT, USER_DT } from 'types'
import { OrderedListOutlined, SearchOutlined, FilterOutlined, ClearOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { PageTitle } from 'components'
import { useNotifications } from 'hooks'
import { type CheckboxChangeEvent } from 'antd/es/checkbox'
import ReportsModal from '../Modals/UserReportsModal'
import { HouseSidingTwoTone, ListAltRounded } from '@mui/icons-material'
import UserGroupsModal from '../Modals/UserGroupsModal'
import AssignReportsFromListUsersModal from '../Modals/AssignReportsFromListUsersModal'
import AddSelectedUsersToUserGroupModal from '../Modals/AddSelectedUsersToUserGroupModal'
import UserOrgsModal from '../Modals/UserOrgsModal'

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

interface DataType {
  key: string
  given_name: string
  family_name: string
  active: string
  verified: string
  sorter?: (a: any, b: any) => number
  responsive?: Breakpoint[]
  render?: (text: any, report: any) => JSX.Element
}
type DataIndex = keyof DataType

const ListUsers: React.FC = () => {
  const { addNotification } = useNotifications()
  const [loading, setLoading] = useState(false)
  const [loadingStates, setLoadingStates] = useState<Record<string, boolean>>({})
  const [users, setUsers] = useState<USER_DT[]>([])
  const [view, setView] = useState(false)
  const [viewAssignReportsFromListUsers, setViewAssignReportsFromListUsers] = useState(false)
  const [viewAddSelectedUsersToUserGroupsFromListUsers, setViewAddSelectedUsersToUserGroupsFromListUsers] = useState(false)
  const [viewUG, setViewUG] = useState(false)
  const [currentReport, setCurrentReport] = useState<CLIENT_REPORTS | null>(null)
  const [userGroups, setUserGroups] = useState<COGNITO_GRP_DT[]>([])
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef<InputRef>(null)
  const [selectedUsers, setSelectedUsers] = useState<USER_DT[]>([])
  const [selectAll, setSelectAll] = useState(false)
  const [filteredUsers, setFilteredUsers] = useState<USER_DT[]>([])
  const [userOrgs, seUserOrgs] = useState<ORG_MEMBER_DT[]>([])
  const [viewUserOrgs, setViewUserOrgs] = useState(false)
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedUsers([])
    } else {
      setSelectedUsers(filteredUsers.length > 0 ? filteredUsers : users)
    }
    setSelectAll(!selectAll)
  }

  useEffect(() => {
    if (selectedUsers.length === users.length) {
      setSelectAll(true)
    } else if (selectAll) {
      setSelectAll(false)
    }
  }, [selectedUsers, users, selectAll])

  const handleSelect = (e: CheckboxChangeEvent, selectedUser: USER_DT) => {
    if (e.target.checked) {
      setSelectedUsers(prev => [...prev, selectedUser])
    } else {
      setSelectedUsers(prev => prev.filter(user => user.sub !== selectedUser.sub))
    }
  }
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setSearchText('')
  }
  async function handleDeleteSelectedUser (): Promise<void> {
    if (selectedUsers.length <= 0) {
      void message.error('Select user to delete')
    } else {
      try {
        setLoading(true)
        const msg = await deleteSelectedUsers(selectedUsers)
        const listOfUsers = await listUsers()
        setUsers(listOfUsers)
        addNotification({
          id: Date.now(),
          type: 'info',
          message: `${msg}`,
          timestamp: Date.now()
        })
        void message.info(msg)
      } catch (error: any) {
        addNotification({
          id: Date.now(),
          type: 'error',
          message: `${error}`,
          timestamp: Date.now()
        })
        void message.error(error?.message ?? 'There was an error deleting the selected user')
      } finally {
        setSelectedUsers([])
        setLoading(false)
      }
    }
  }

  async function handleDeactivateSelectedUser (): Promise<void> {
    if (selectedUsers.length <= 0) {
      void message.error('Select user to deactivate')
    } else {
      try {
        setLoading(true)
        const msg = await deactivateUsers(selectedUsers)
        const listOfUsers = await listUsers()
        setUsers(listOfUsers)
        addNotification({
          id: Date.now(),
          type: 'info',
          message: `${msg}`,
          timestamp: Date.now()
        })
        void message.info(msg)
      } catch (error: any) {
        addNotification({
          id: Date.now(),
          type: 'error',
          message: `${error}`,
          timestamp: Date.now()
        })
        void message.error(error?.message ?? 'There was an error deactivating the selected user')
      } finally {
        setSelectedUsers([])
        setLoading(false)
      }
    }
  }

  async function handleAddSelectedUsersToUserGroups (): Promise<void> {
    if (selectedUsers.length <= 0) {
      void message.error('Select user to add to group')
    } else {
      try {
        setLoading(true)
        setViewAddSelectedUsersToUserGroupsFromListUsers(true)
      } catch (error: any) {
        addNotification({
          id: Date.now(),
          type: 'error',
          message: `${error}`,
          timestamp: Date.now()
        })
        void message.error(error?.message ?? 'There was an error adding selected users to the user groups')
      } finally {
        setSelectedUsers([])
        setLoading(false)
      }
    }
  }
  async function handleActivateSelectedUser (): Promise<void> {
    if (selectedUsers.length <= 0) {
      void message.error('Select user to activate')
    } else {
      try {
        setLoading(true)
        const msg = await activateUsers(selectedUsers)
        const listOfUsers = await listUsers()
        setUsers(listOfUsers)
        addNotification({
          id: Date.now(),
          type: 'info',
          message: `${msg}`,
          timestamp: Date.now()
        })
        void message.info(msg)
      } catch (error: any) {
        addNotification({
          id: Date.now(),
          type: 'error',
          message: `${error}`,
          timestamp: Date.now()
        })
        void message.error(error?.message ?? 'There was an error activating the selected users')
      } finally {
        setSelectedUsers([])
        setLoading(false)
      }
    }
  }

  async function handleAssignReportToSelectedUser (): Promise<void> {
    if (selectedUsers.length <= 0) {
      void message.error('Select user to proceed')
    } else {
      try {
        setLoading(true)
        setViewAssignReportsFromListUsers(true)
      } catch (error: any) {
        addNotification({
          id: Date.now(),
          type: 'error',
          message: `${error}`,
          timestamp: Date.now()
        })
        void message.error(error?.message ?? 'There was an error assigning the selected users to the reports')
      } finally {
        setSelectedUsers([])
        setLoading(false)
      }
    }
  }

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<USER_DT> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => { e.stopPropagation() }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => { setSelectedKeys(e.target.value ? [e.target.value] : []) }}
                    onPressEnter={() => { handleSearch(selectedKeys as string[], confirm, dataIndex) }}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        className='button'
                        type="primary"
                        onClick={() => { handleSearch(selectedKeys as string[], confirm, dataIndex) }}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        className='button'
                        type="primary"
                        onClick={() => { clearFilters && handleReset(clearFilters) }}
                        icon={<ClearOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        icon={<FilterOutlined />}
                        style={{ color: '#d67632' }}
                        onClick={() => {
                          confirm({ closeDropdown: false })
                          setSearchText((selectedKeys as string[])[0])
                          setSearchedColumn(dataIndex)
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        icon={<CloseCircleOutlined />}
                        style={{ color: '#d67632' }}
                        onClick={() => {
                          close()
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
    ),
    filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#d67632' : undefined }} />
    ),
    onFilter: (value, record: any) => {
      return (record && dataIndex && record[dataIndex])
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase())
        : false
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex
        ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
          )
        : (
            text
          )
  })

  const columns: ColumnsType<USER_DT> = [
    {
      title: <Checkbox checked={selectAll} onChange={handleSelectAll} />,
      dataIndex: 'select',
      render: (_: any, record: USER_DT) => (
                <Checkbox
                    checked={selectedUsers.includes(record)}
                    onChange={(e) => { handleSelect(e, record) }}
                />
      ),
      responsive: ['xl']
    },
    {
      title: 'Given Name',
      dataIndex: 'given_name',
      key: 'given_name',
      sorter: (a: any, b: any) => a.given_name.localeCompare(b.given_name),
      ...getColumnSearchProps('given_name'),
      responsive: ['sm']
    },
    {
      title: 'Family Name',
      dataIndex: 'family_name',
      key: 'family_name',
      sorter: (a: any, b: any) => a.family_name.localeCompare(b.family_name),
      ...getColumnSearchProps('family_name')
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      responsive: ['xxl']
    },
    {
      title: 'Created On',
      dataIndex: 'create_date',
      key: 'create_date',
      sorter: (a: any, b: any) => a.create_date.localeCompare(b.create_date),
      responsive: ['xl']
    },
    {
      title: 'Modified On',
      dataIndex: 'last_modified_date',
      key: 'last_modified_date',
      sorter: (a: any, b: any) => a.last_modified_date.localeCompare(b.last_modified_date),
      responsive: ['xxl']
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      sorter: (a: any, b: any) => a.active.localeCompare(b.active),
      render: (active: string) => {
        let color
        if (active === 'Yes') {
          color = 'green'
        } else {
          color = 'volcano'
        }
        return (
                    <Tag color={color} key={active}>
                        {active.toUpperCase()}
                    </Tag>
        )
      },
      ...getColumnSearchProps('active'),
      responsive: ['sm']
    },
    {
      title: 'Confirmed',
      dataIndex: 'verified',
      key: 'verified',
      sorter: (a: any, b: any) => a.verified.localeCompare(b.verified),
      render: (verified: string) => {
        let color
        if (verified === 'Yes') {
          color = 'green'
        } else {
          color = 'volcano'
        }
        return (
                    <Tag color={color} key={verified}>
                        {verified.toUpperCase()}
                    </Tag>
        )
      },
      ...getColumnSearchProps('verified'),
      responsive: ['sm']
    },
    {
      title: 'Reports',
      key: 'reports',
      render: (text: any, user: USER_DT) => (
                <Button
                    // disabled={user.verified !== 'Yes'}
                    icon={<OrderedListOutlined />}
                    loading={loadingStates[`${user.sub}_reports`]}
                    onClick={async () => {
                      try {
                        setLoadingStates(prev => ({ ...prev, [`${user.sub}_reports`]: true }))
                        const params = {
                          user_id: user.sub
                        }
                        const userReports = await adminListUserReportsReport(params)
                        if (userReports) {
                          setCurrentReport(userReports)
                          setView(true)
                        } else {
                          void message.info('The user is not assigned any reports')
                        }
                      } catch (error: any) {
                        addNotification({
                          id: Date.now(),
                          type: 'info',
                          message: `${error}`,
                          timestamp: Date.now()
                        })
                        notification.info({
                          message: error?.message ?? `An error occurred trying to get reports for ${user.given_name} ${user.family_name}`
                        })
                        console.log(error)
                      } finally {
                        setLoadingStates(prev => ({ ...prev, [`${user.sub}_reports`]: false }))
                      }
                    }}

                />
      ),
      responsive: ['xl']
    },
    {
      title: 'User Group',
      key: 'groups',
      render: (text: any, user: USER_DT) => (
                <Button
                    icon={<ListAltRounded />}
                    loading={loadingStates[`${user.sub}_groups`]}
                    onClick={async () => {
                      try {
                        setLoadingStates(prev => ({ ...prev, [`${user.sub}_groups`]: true }))
                        const params = {
                          client_sub: user.sub
                        }
                        const userGroup = await listCognitoGroupsForAUser(params)
                        if (userGroup.length > 0) {
                          setUserGroups(userGroup)
                          setViewUG(true)
                        } else {
                          void message.info('The user is not assigned any group')
                        }
                      } catch (error: any) {
                        addNotification({
                          id: Date.now(),
                          type: 'error',
                          message: `${error}`,
                          timestamp: Date.now()
                        })
                        notification.error({
                          message: error?.message ?? `An error occurred trying to get reports for ${user.given_name} ${user.family_name}`
                        })
                        console.log(error)
                      } finally {
                        setLoadingStates(prev => ({ ...prev, [`${user.sub}_groups`]: false }))
                      }
                    }}

                />
      ),
      responsive: ['xl']
    },
    {
      title: 'Orgs',
      key: 'organizations',
      render: (text: any, user: USER_DT) => (
                <Button
                    icon={<HouseSidingTwoTone />}
                    loading={loadingStates[`${user.sub}_orgs`]}
                    onClick={async () => {
                      try {
                        setLoadingStates(prev => ({ ...prev, [`${user.sub}_orgs`]: true }))
                        const params = {
                          usr_id: user.sub
                        }
                        const userOrgs = await listUserOrgs(params)
                        seUserOrgs(userOrgs.reports)
                        setViewUserOrgs(true)
                      } catch (error: any) {
                        addNotification({
                          id: Date.now(),
                          type: 'error',
                          message: `${error}`,
                          timestamp: Date.now()
                        })
                        notification.error({
                          message: error?.message ?? `An error occurred trying to get organizations for ${user.given_name} ${user.family_name}`
                        })
                        console.log(error)
                      } finally {
                        setLoadingStates(prev => ({ ...prev, [`${user.sub}_orgs`]: false }))
                      }
                    }}

                />
      )
    }
  ]

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const listOfUsers = await listUsers()
        setUsers(listOfUsers)
      } catch (error) {
        console.error('Error fetching the users', error)
        try {
          addNotification({
            id: Date.now(),
            type: 'error',
            message: `${error}`,
            timestamp: Date.now()
          })
        } catch (innerError) {
          console.log(innerError)
        }
        notification.error({
          message: 'Error getting users',
          description: `${error}.`
        })
      } finally {
        setLoading(false)
      }
    }

    void fetchData()
  }, [])

  const areAllSelectedUsersDeactivated = () => {
    if (selectedUsers && selectedUsers.length > 0) {
      return selectedUsers.every(user => user.active === 'No')
    }
    return false
  }

  const areAllSelectedUsersActivated = () => {
    if (selectedUsers && selectedUsers.length > 0) {
      return selectedUsers.every(user => user.active === 'Yes')
    }
    return false
  }
  return (
        <div>
            <PageTitle text="All Application Users" />
            {
                (selectedUsers && selectedUsers.length > 0)
                  ? <>
                        <Space>
                        {areAllSelectedUsersDeactivated()
                          ? (
                            <>
                            <Button type="link" danger onClick={handleDeleteSelectedUser}>
                              Delete
                            </Button>
                            <Button type="link" style={{
                              color: '#d67632'
                            }} onClick={handleActivateSelectedUser}>Activate</Button>
                            </>
                            )
                          : null
                          }
                        {areAllSelectedUsersActivated()
                          ? (
                            <>
                            <Button type="link" danger onClick={handleDeactivateSelectedUser}>Deactivate</Button>
                            </>
                            )
                          : null
                          }
                            <Button type="link" style={{
                              color: '#d67632'
                            }} onClick={handleAddSelectedUsersToUserGroups}>Add to Group</Button>
                            <Button type="link" style={{
                              color: '#d67632'
                            }} onClick={handleAssignReportToSelectedUser}>Assign Report</Button>
                        </Space>
                    </>
                  : null
            }
            {loading
              ? (
                    <Spin />
                )
              : (
                    <>
                      <AddSelectedUsersToUserGroupModal
                       visible={viewAddSelectedUsersToUserGroupsFromListUsers}
                       users={selectedUsers}
                       onClose={() => {
                         setViewAddSelectedUsersToUserGroupsFromListUsers(false)
                       }} />
                    <AssignReportsFromListUsersModal
                       visible={viewAssignReportsFromListUsers}
                       users={selectedUsers}
                       onClose={() => {
                         setViewAssignReportsFromListUsers(false)
                       }} />
                        <ReportsModal
                            visible={view}
                            reports={currentReport?.reports ?? []}
                            onClose={() => {
                              setView(false)
                              setCurrentReport(null)
                            }}
                        />
                        <UserGroupsModal
                            visible={viewUG}
                            groups={userGroups ?? []}
                            onClose={() => {
                              setViewUG(false)
                              setUserGroups([])
                            }}
                        />
                        <UserOrgsModal
                      visible={viewUserOrgs}
                      userOrgs={userOrgs}
                      onClose={() => {
                        setViewUserOrgs(false)
                      }} />
                        <Table
                        dataSource={users}
                        bordered
                        columns={columns}
                        rowKey="id"
                        onChange={(pagination, filters, sorter, { currentDataSource }) => {
                          setFilteredUsers(currentDataSource)
                        }}
                      />
                      </>
                )}
        </div>
  )
}

export default ListUsers
