import React, { useState } from 'react'
import { Button, Checkbox, Modal, Table, message, Space } from 'antd'
import type { COGNITO_GRP_DT, COGNITO_GRP_MEMBER_DT } from 'types'
import { Disclaimer, PageTitle } from 'components'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import { useNotifications } from 'hooks'
import { removeUserFromCognitoGroup } from 'services'
type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

interface GroupColumn {
  key: string
  title: string
  dataIndex: string
  sorter?: (a: any, b: any) => number
  responsive?: Breakpoint[]
  render?: (text: any, report: any) => JSX.Element
}

interface GroupsModalProps {
  visible: boolean
  groups: COGNITO_GRP_DT[]
  onClose: () => void
}

// interface UserGroupReport {
//   usr_id: string
//   group_name: string
//   group_desc: string
//   created_at?: string
//   modified_at?: string
// }

const UserGroupsModal: React.FC<GroupsModalProps> = ({ visible, groups, onClose }) => {
  const [loading, setLoading] = useState(false)
  const { addNotification } = useNotifications()
  // async function onDelete (report: any) {
  //   try {
  //     setLoading(true)
  //     const msg = await deleteSelectedUserReports([report])
  //     void message.success(msg || `${report.report_name} removed successfully`)
  //   } catch (error: any) {
  //     addNotification({
  //       id: Date.now(),
  //       type: 'error',
  //       message: `${error}`,
  //       timestamp: Date.now()
  //     })
  //     void message.error(error?.message ?? 'There was an error deleting the selected reports')
  //   } finally {
  //     setLoading(false)
  //   }
  // }
  const [selectedGroups, setSelectedGroups] = useState<COGNITO_GRP_MEMBER_DT[]>([])
  const handleSelect = (e: CheckboxChangeEvent, selectedReport: COGNITO_GRP_MEMBER_DT) => {
    if (e.target.checked) {
      setSelectedGroups(prev => [...prev, selectedReport])
    } else {
      setSelectedGroups(prev => prev.filter(report => report.group_name !== selectedReport.group_name))
    }
  }
  const userReportsColumns: GroupColumn[] = [
    {
      key: 'id',
      title: 'Select',
      dataIndex: 'id',
      render: (_: any, record: any) => <Checkbox style={{
      }} onChange={e => { handleSelect(e, record) }} />
    },
    {
      title: 'Group Name',
      dataIndex: 'group_name',
      key: 'group_name',
      sorter: (a, b) => a.group_name.localeCompare(b.group_name)
    },
    {
      title: 'Description',
      dataIndex: 'group_desc',
      key: 'group_name',
      responsive: ['lg']
    }
  ]

  async function handleRemoveUserFromSelectedGroups (): Promise<void> {
    if (selectedGroups.length <= 0) {
      void message.error('Select group to remove')
    } else {
      try {
        setLoading(true)
        const msg = await removeUserFromCognitoGroup(selectedGroups)
        void message.info(msg)
        onClose()
      } catch (error: any) {
        addNotification({
          id: Date.now(),
          type: 'error',
          message: `${error}`,
          timestamp: Date.now()
        })
        void message.error(error?.message ?? 'There was an error removing the user from the selected groups')
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
    >
      <PageTitle text="User's Groups" />
      <Disclaimer
                title="⚠️ Important Note"
                message="Be careful with this action as it is going to remove accesses to reports and also, if you are included and removing admins group, you will loss access immediately to perform admin roles"
      />
      {
                (selectedGroups && selectedGroups.length > 0)
                  ? <>
                        <Space>
                            <Button loading={loading} type="link" danger onClick={handleRemoveUserFromSelectedGroups}>Remove</Button>
                        </Space>
                    </>
                  : null
            }
      <Table
        dataSource={groups}
        columns={userReportsColumns}
        rowKey="group_name"
        bordered
        pagination={{ pageSize: 10, responsive: true }}
        scroll={{ x: 'max-content' }}
      />
    </Modal>
  )
}

export default UserGroupsModal
