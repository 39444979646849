import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Modal, Table, message, Space, notification } from 'antd'
import type { ADMIN_REPORT, COGNITO_GRP_DT } from 'types'
import { PageTitle } from 'components'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import { useNotifications } from 'hooks'
import { assignUserGroups, listCognitoUserGroups } from 'services'
type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

interface GroupColumn {
  key: string
  title: string
  dataIndex: string
  sorter?: (a: any, b: any) => number
  responsive?: Breakpoint[]
  render?: (text: any, report: any) => JSX.Element
}

interface GroupsModalProps {
  visible: boolean
  reports: ADMIN_REPORT[]
  onClose: () => void
}

interface UserGroupReport {
  usr_id: string
  group_name: string
  group_desc: string
  created_at?: string
  modified_at?: string
}

const UserGroupAssignmentModal: React.FC<GroupsModalProps> = ({ visible, reports, onClose }) => {
  const [loading, setLoading] = useState(false)
  const { addNotification } = useNotifications()
  const [groups, setGroups] = useState<COGNITO_GRP_DT[]>([])
  // async function onDelete (report: any) {
  //   try {
  //     setLoading(true)
  //     const msg = await deleteSelectedUserReports([report])
  //     void message.success(msg || `${report.report_name} removed successfully`)
  //   } catch (error: any) {
  //     addNotification({
  //       id: Date.now(),
  //       type: 'error',
  //       message: `${error}`,
  //       timestamp: Date.now()
  //     })
  //     void message.error(error?.message ?? 'There was an error deleting the selected reports')
  //   } finally {
  //     setLoading(false)
  //   }
  // }
  const [selectedGroups, setSelectedGroups] = useState<UserGroupReport[]>([])
  const handleSelect = (e: CheckboxChangeEvent, selectedReport: UserGroupReport) => {
    if (e.target.checked) {
      setSelectedGroups(prev => [...prev, selectedReport])
    } else {
      setSelectedGroups(prev => prev.filter(report => report.group_name !== selectedReport.group_name))
    }
  }
  const userReportsColumns: GroupColumn[] = [
    {
      key: 'id',
      title: 'Select',
      dataIndex: 'id',
      render: (_: any, record: any) => <Checkbox style={{
      }} onChange={e => { handleSelect(e, record) }} />
    },
    {
      title: 'Group Name',
      dataIndex: 'group_name',
      key: 'group_name',
      sorter: (a, b) => a.group_name.localeCompare(b.group_name)
    },
    {
      title: 'Description',
      dataIndex: 'group_desc',
      key: 'group_name',
      responsive: ['lg']
    }
  ]

  async function handleAssignSelectedUserGroups (): Promise<void> {
    if (selectedGroups.length <= 0) {
      void message.error('Select group to assign')
    } else {
      try {
        setLoading(true)
        const payload = {
          reports,
          groups: selectedGroups
        }
        const msg = await assignUserGroups(payload)
        addNotification({
          id: Date.now(),
          type: 'info',
          message: `${msg}`,
          timestamp: Date.now()
        })
        void message.info(msg)
        onClose()
      } catch (error: any) {
        addNotification({
          id: Date.now(),
          type: 'error',
          message: `${error}`,
          timestamp: Date.now()
        })
        void message.error(error?.message ?? 'There was an error assigning the selected reports to the user groups')
      } finally {
        setLoading(false)
      }
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const listOfGroups = await listCognitoUserGroups()
        setGroups(listOfGroups)
      } catch (error) {
        try {
          addNotification({
            id: Date.now(),
            type: 'error',
            message: `${error}`,
            timestamp: Date.now()
          })
        } catch (innerError) {
          console.log(innerError)
        }
        notification.error({
          message: 'Error getting user groups',
          description: `${error}.`
        })
      } finally {
        setLoading(false)
      }
    }

    void fetchData()
  }, [])

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
    >
      <PageTitle text="User's Groups" />
      {
                (selectedGroups && selectedGroups.length > 0)
                  ? <>
                        <Space>
                            <Button style={{
                              color: '#d67632'
                            }}
                            loading={loading} type="link" onClick={handleAssignSelectedUserGroups}>Assign</Button>
                        </Space>
                    </>
                  : null
            }
      <Table
        dataSource={groups}
        columns={userReportsColumns}
        rowKey="group_name"
        bordered
        loading={loading}
        pagination={{ pageSize: 10, responsive: true }}
        scroll={{ x: 'max-content' }}
      />
    </Modal>
  )
}

export default UserGroupAssignmentModal
