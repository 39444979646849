import React, { useEffect, useRef, useState } from 'react'
import type { ORG_MEMBER_DT, PROPMAN_ORGS } from 'types'
import { PageTitle } from 'components'
import { useNotifications } from 'hooks'
import Highlighter from 'react-highlight-words'
import { Button, Input, Space, Table, Spin, notification, type InputRef } from 'antd'
import type { FilterConfirmProps } from 'antd/es/table/interface'
import type { ColumnType, ColumnsType } from 'antd/es/table'
import { getOrganizations, listOrgMembers } from 'services'
import { SearchOutlined, FilterOutlined, ClearOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { type CheckboxChangeEvent } from 'antd/es/checkbox'
import OrgMembershipModal from '../Modals/OrgMembershipModal'
import { GroupOutlined } from '@mui/icons-material'

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
interface DataType {
  key: string
  propman_id: string
  organization: string
  created_on: string
  modified_on: string
  status: string
  sorter?: (a: any, b: any) => number
  responsive?: Breakpoint[]
  render?: (text: any, report: any) => JSX.Element
}
type DataIndex = keyof DataType

const ListPropmanOrgs: React.FC = () => {
  const { addNotification } = useNotifications()
  const [loading, setLoading] = useState(false)
  const [viewOrgMembers, setViewOrgMembers] = useState(false)
  const [orgs, setOrgs] = useState<PROPMAN_ORGS[]>([])
  const [orgMembers, seOrgMembers] = useState<ORG_MEMBER_DT[]>([])
  const [loadingStates, setLoadingStates] = useState<Record<string, boolean>>({})
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef<InputRef>(null)
  const [selectedUserGroups, setSelectedUserGroups] = useState<PROPMAN_ORGS[]>([])
  const [selectAll, setSelectAll] = useState(false)

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedUserGroups([])
    } else {
      setSelectedUserGroups(orgs)
    }
    setSelectAll(!selectAll)
  }

  // useEffect(() => {
  //   if (selectedUserGroups.length === orgs.length) {
  //     setSelectAll(true)
  //   } else if (selectAll) {
  //     setSelectAll(false)
  //   }
  // }, [selectedUserGroups, orgs, selectAll])

  const handleSelect = (e: CheckboxChangeEvent, selectedOrg: PROPMAN_ORGS) => {
    if (e.target.checked) {
      setSelectedUserGroups(prev => [...prev, selectedOrg])
    } else {
      setSelectedUserGroups(prev => prev.filter(org => org.propman_id !== selectedOrg.propman_id))
    }
  }
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<PROPMAN_ORGS> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => { e.stopPropagation() }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => { setSelectedKeys(e.target.value ? [e.target.value] : []) }}
                    onPressEnter={() => { handleSearch(selectedKeys as string[], confirm, dataIndex) }}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        className='button'
                        type="primary"
                        onClick={() => { handleSearch(selectedKeys as string[], confirm, dataIndex) }}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        className='button'
                        type="primary"
                        onClick={() => { clearFilters && handleReset(clearFilters) }}
                        icon={<ClearOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        icon={<FilterOutlined />}
                        style={{ color: '#d67632' }}
                        onClick={() => {
                          confirm({ closeDropdown: false })
                          setSearchText((selectedKeys as string[])[0])
                          setSearchedColumn(dataIndex)
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        icon={<CloseCircleOutlined />}
                        style={{ color: '#d67632' }}
                        onClick={() => {
                          close()
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
    ),
    filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#d67632' : undefined }} />
    ),
    onFilter: (value, record: any) => {
      return (record && dataIndex && record[dataIndex])
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase())
        : false
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex
        ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
          )
        : (
            text
          )
  })

  const columns: ColumnsType<PROPMAN_ORGS> = [
    // {
    //   title: <Checkbox checked={selectAll} onChange={handleSelectAll} />,
    //   dataIndex: 'select',
    //   render: (_: any, record: PROPMAN_ORGS) => (
    //             <Checkbox
    //                 checked={selectedUserGroups.includes(record)}
    //                 onChange={(e) => { handleSelect(e, record) }}
    //             />
    //   )
    // },
    {
      title: 'Propman ID',
      dataIndex: 'propman_id',
      key: 'propman_id',
      sorter: (a: any, b: any) => a.propman_id.localeCompare(b.propman_id),
      ...getColumnSearchProps('propman_id')
    },
    {
      title: 'Organization',
      dataIndex: 'organization',
      key: 'organization',
      sorter: (a: any, b: any) => a.organization.localeCompare(b.organization),
      ...getColumnSearchProps('organization')
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a: any, b: any) => a.status.localeCompare(b.status),
      ...getColumnSearchProps('status')
    },
    {
      title: 'Created On',
      dataIndex: 'created_on',
      key: 'created_on',
      sorter: (a: any, b: any) => a.created_on.localeCompare(b.created_on),
      ...getColumnSearchProps('created_on')
    },
    {
      title: 'Modified On',
      dataIndex: 'modified_on',
      key: 'modified_on',
      sorter: (a: any, b: any) => a.modified_on.localeCompare(b.modified_on),
      ...getColumnSearchProps('modified_on')
    },
    {
      title: 'Members',
      key: 'members',
      render: (text: any, org: PROPMAN_ORGS) => (
                <Button
                    icon={<GroupOutlined />}
                    loading={loadingStates[`${org.propman_id}_members`]}
                    onClick={async () => {
                      try {
                        setLoadingStates(prev => ({ ...prev, [`${org.propman_id}_members`]: true }))
                        const params = {
                          org_id: org.propman_id
                        }
                        const orgMembers = await listOrgMembers(params)
                        seOrgMembers(orgMembers.reports)
                        setViewOrgMembers(true)
                      } catch (error: any) {
                        addNotification({
                          id: Date.now(),
                          type: 'error',
                          message: `${error}`,
                          timestamp: Date.now()
                        })
                        notification.error({
                          message: error?.message ?? `An error occurred trying to get members for ${org.propman_id}`
                        })
                        console.log(error)
                      } finally {
                        setLoadingStates(prev => ({ ...prev, [`${org.propman_id}_members`]: false }))
                      }
                    }}

                />
      )
    }
  ]

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const listOfPropOrgs = await getOrganizations()
        setOrgs(listOfPropOrgs.reports)
      } catch (error) {
        try {
          addNotification({
            id: Date.now(),
            type: 'error',
            message: `${error}`,
            timestamp: Date.now()
          })
        } catch (innerError) {
          console.log(innerError)
        }
        notification.error({
          message: 'Error getting organizations',
          description: `${error}.`
        })
      } finally {
        setLoading(false)
      }
    }

    void fetchData()
  }, [])

  return (
        <div>
            <PageTitle text="Propman Customer Organizations" />
            {loading
              ? (
                    <Spin />
                )
              : (
                    <>
                    <OrgMembershipModal
                      visible={viewOrgMembers}
                      orgMembers={orgMembers}
                      onClose={() => {
                        setViewOrgMembers(false)
                      }} />
                        <Table dataSource={orgs} bordered columns={columns} rowKey="id" /></>
                )}
        </div>
  )
}

export default ListPropmanOrgs
