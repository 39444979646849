import React, { useContext, useEffect, useState } from 'react'
import { Button, Typography, Col } from 'antd'
import logo from 'assets/logo-with-name.png'
import DoubleArrowOutlinedIcon from '@mui/icons-material/DoubleArrowOutlined'
import { USER_ACTION_TYPE_ENUMS } from 'appConstants'
import { ROUTES } from 'config/constants'
import { getUser } from 'services'
import type { USER_DT } from 'types'
import { useNavigate } from 'react-router-dom'
import { UserContext } from 'context'
import { Auth } from 'aws-amplify'
import type { CognitoUser } from '@aws-amplify/auth'
import PleaseWaitOverlay from '../admin-portal/Modals/PleaseWaitOverlay'
const { Title, Paragraph } = Typography

const HomePage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const { dispatch } = useContext(UserContext)
  const navigate = useNavigate()
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const isMobile = windowWidth <= 768

  const responsiveStyles = {
    title: isMobile
      ? {
          fontSize: '36px',
          marginBottom: '5px'
        }
      : {},
    subtitle: isMobile
      ? {
          fontSize: '20px',
          marginBottom: '20px'
        }
      : {},
    titleContainer: isMobile
      ? {
          fontSize: '20px',
          marginBottom: '20px'
        }
      : {},
    logo: isMobile
      ? {
          maxWidth: '300px'
        }
      : {},
    button: isMobile
      ? {
          fontSize: '20px',
          padding: '8px 20px'
        }
      : {},
    description: isMobile
      ? {
          fontSize: '20px',
          marginTop: '50px'
        }
      : {}
  }
  const letsGo = async function () {
    try {
      setLoading(true)
      const userData: USER_DT = await getUser()
      dispatch({ type: USER_ACTION_TYPE_ENUMS.AUTHENTICATED, user: userData })

      if (!userData?.sub) {
        navigate(ROUTES.LOGIN)
        setLoading(false)
        return
      }
      if (!userData?.email_verified) {
        navigate(ROUTES.VERIFY_EMAIL)
        setLoading(false)
        return
      }
      if (userData?.email_verified && userData?.userIsAdmin) {
        navigate(ROUTES.ADMIN)
        setLoading(false)
      } else if (
        userData?.email_verified &&
            (userData?.userIsAdmin !== undefined || userData?.userIsAdmin !== null) &&
            !userData?.userIsAdmin
      ) {
        navigate(ROUTES.DASHBOARD)
        setLoading(false)
      }
    } catch (err: any) {
      console.log('this is the homepage navigation error: ', err)

      // Handle specific errors based on their type or message (e.g., preflight error)
      if (err.message.includes('preflight')) {
        setError('Network issues detected. Please try again later.')
      } else {
        setError('An unexpected error occurred. Please try again later.')
      }

      // Default behavior in case of unexpected errors
      navigate(ROUTES.ERROR) // Assuming you have an error route
    }
  }

  useEffect(function () {
    const checkUser = async () => {
      try {
        const authenticated: CognitoUser = await Auth.currentAuthenticatedUser()
        if (authenticated.getUsername()) {
          await letsGo()
        }
      } catch (error) {

      }
    }
    void checkUser()
  }, [])
  return (
    <div style={styles.container}>
        <Col style={{ ...styles.titleContainer, ...responsiveStyles.titleContainer }}>
          <Title level={1} style={{ ...styles.title, ...responsiveStyles.title }}>
            Grosvenor Systems Dashboard
          </Title>
          <Title level={3} style={{ ...styles.subtitle, ...responsiveStyles.subtitle }}>
            Exploring Insights
          </Title>
        </Col>
        <Col style={styles.logoContainer}>
          <img src={logo} alt="Grosvenor Systems Logo" style={{ ...styles.logo, ...responsiveStyles.logo }} />
        </Col>
        <Button
          type="primary"
          size="large"
          style={{ ...styles.button, ...responsiveStyles.button }}
          loading={loading}
          onClick={letsGo}
        >
          <DoubleArrowOutlinedIcon />
          Let&apos;s Go!
        </Button>
        <PleaseWaitOverlay visible={loading} />
        <Paragraph style={{ ...styles.description, ...responsiveStyles.description }}>
          This is the home page for Grosvenor Systems Quicksight Dashboard. This dynamic platform provides a comprehensive view of key metrics, offering a clear understanding of our snapshots. Explore the power of data visualization to make informed decisions and optimize your strategies.
        </Paragraph>
    </div>
  )
}

const styles = {
  container: {
    padding: '50px',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center' as 'center',
    justifyContent: 'center' as 'center',
    height: '100vh',
    overflow: 'auto' // added overflow auto
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    backgroundColor: '#5f2d78',
    justifyContent: 'center' as 'center',
    alignItems: 'center' as 'center',
    width: '100%',
    padding: '10px',
    borderRadius: '10px',
    boxSizing: 'border-box' as 'border-box',
    overflow: 'hidden'
  },
  title: {
    textAlign: 'center' as 'center',
    marginBottom: '1px',
    color: 'white',
    fontWeight: 800,
    fontSize: '56px'
  },
  subtitle: {
    fontFamily: 'Lato, sans-serif',
    textAlign: 'center' as 'center',
    marginBottom: '40px',
    color: 'white',
    fontWeight: 10,
    fontSize: '26px'
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center' as 'center',
    alignItems: 'center' as 'center',
    marginBottom: '30px'
  },
  logo: {
    width: '100%',
    maxWidth: '500px'
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    border: '2px solid #d67632',
    outline: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s, color 0.3s',
    backgroundColor: '#3c174f',
    color: 'white',
    fontWeight: 400,
    padding: '10px 30px',
    fontSize: '26px'
  },
  description: {
    textAlign: 'center' as 'center',
    maxWidth: '800px',
    lineHeight: '1.5',
    marginTop: '100px',
    margin: '0 auto',
    fontWeight: 20,
    color: 'white',
    fontSize: '26px'
  }
}

export { HomePage }
