import { appStateEnums } from 'appConstants'
import React, { type ReactNode, createContext, useContext, useReducer, useEffect } from 'react'
import { appReducer } from 'reducers/appReducer'
import type { AppContextProps, AppStates } from 'types'
interface AppProps {
  children: ReactNode
}

const dummyDispatch = () => {} // A no-op function.

const defaultContextValue: AppContextProps = {
  state: {
    theme: 'dark'
  },
  dispatch: dummyDispatch
}

const AppContext = createContext<AppContextProps>(defaultContextValue)

const AppProvider: React.FC<AppProps> = ({ children }) => {
  const APP_STATE_KEY = 'app_states'
  const initialState: AppStates = {
    theme: 'dark'
  }

  const [state, dispatch] = useReducer(appReducer, initialState)

  useEffect(() => {
    // Pool the initial states from local storage
    const storedStates = localStorage.getItem(APP_STATE_KEY)

    if (storedStates) {
      try {
        const parsedStates = JSON.parse(storedStates)

        // Merge stored states with initial states
        const updatedStates = {
          ...initialState,
          ...parsedStates
        }
        console.log(initialState, parsedStates, updatedStates)
        // Dispatch action to update the states
        dispatch({ type: appStateEnums.INIT_STATES, ...updatedStates })
      } catch (error) {
        console.error('Failed to parse app states from local storage:', error)
      }
    }
  }, [])

  useEffect(() => {
    localStorage.setItem(APP_STATE_KEY, JSON.stringify(state))
  }, [state])

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  )
}

export { AppContext, AppProvider }
