import React, { useState } from 'react'
import { Form, Input, Button, notification } from 'antd'
import { createPropmanOrg } from 'services'
import type { PROPMAN_DT } from 'types'
import { Disclaimer, PageTitle } from 'components'
import { useNotifications } from 'hooks'
import { trimWhitespace } from 'utils'

const CreatePropmenOrg: React.FC = () => {
  const { addNotification } = useNotifications()
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const onFinish = async (values: PROPMAN_DT) => {
    setLoading(true)
    try {
      console.log(values)
      const msg = await createPropmanOrg(values)
      notification.success({
        message: 'Propman Org Created Successfully',
        description: msg || `Group ${values?.propman_id} has been created successfully.`
      })
      form.resetFields()
    } catch (error: any) {
      try {
        addNotification({
          id: Date.now(),
          type: 'error',
          message: `${error}`,
          timestamp: Date.now()
        })
      } catch (innerError) {
        console.log(innerError)
      }
      notification.error({
        message: 'Propman Org Creation Failed',
        description: error?.message || 'There was an error creating the propman org.'
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <PageTitle text="Create and Register a Propman Org ID for customer" />
      <Form
        form={form}
        name="create-propman-org"
        onFinish={onFinish}
        layout="vertical"
        style={{ maxWidth: '500px' }}
      >
              <Disclaimer
                title="⚠️ Important Note"
                message="The Organization ID (Propman ID) must be unique, first 5 characters must be uppercase, last three characters must be digits."
            />
        <Form.Item
          label="Propman ID"
          name="propman_id"
          getValueFromEvent={trimWhitespace}
          rules={[
            { required: true, message: 'Please input the propman id!' },
            { pattern: /^[A-Z]{5}\d{3}$/, message: 'Propman ID must follow the the pattern MANAG000. That is 5 uppercase letters and 3 digits' }
          ]}
        >
          <Input
           />
        </Form.Item>
        <Form.Item
          label="Customer/Organization Name"
          name="organization"
          // getValueFromEvent={trimWhitespace}
          rules={[
            { required: true, message: 'Please input the organization name or description!' },
            { pattern: /^[A-Za-z0-9_ ]+$/, message: 'Organization name or description should only contain alphanumeric characters and spaces.' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button className='button' type="primary" htmlType="submit" loading={loading}>
            Create Propman Org
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default CreatePropmenOrg
