import { appStateEnums } from 'appConstants'
import { AppContext } from 'context'
import React, { useContext } from 'react'

interface DisclaimerProps {
  hide?: boolean
  title?: string
  message: string
}

const Disclaimer: React.FC<DisclaimerProps> = ({ title, message }) => {
  const { state, dispatch } = useContext(AppContext)
  const hideMe = function () {
    dispatch({ type: appStateEnums.HIDE_IMPORTANT_NOTIFICATIONS, hideImportantMessages: true })
  }

  return (
    <div>
      {
      state.hideImportantMessages
        ? null
        : (
        <div style={styles.container}>
          <button style={styles.hideButton} onClick={hideMe}>Hide</button>
          <h5 style={styles.header}>{title}</h5>
          <p style={styles.message}>{message}</p>
        </div>
          )}
    </div>
  )
}

const styles = {
  container: {
    border: '2px solid #ff9c00',
    padding: '5px 10px',
    backgroundColor: '#fff5e6',
    margin: '10px 0',
    borderRadius: '5px',
    position: 'relative' as 'relative'
  },
  header: {
    color: '#d43f00',
    marginBottom: '8px',
    fontSize: '15px',
    fontWeight: 700
  },
  message: {
    color: '#d43f00',
    marginBottom: '8px',
    fontSize: '15px',
    fontWeight: 300
  },
  hideButton: {
    position: 'absolute' as 'absolute',
    top: '5px',
    right: '5px',
    background: 'none',
    border: 'none',
    cursor: 'pointer' as 'pointer',
    fontSize: '14px',
    color: '#d43f00',
    fontWeight: 700
  }
}

export default Disclaimer
