import React from 'react'
import {
  Space,
  Table,
  Input,
  Spin,
  Button,
  Tag
} from 'antd'
import Highlighter from 'react-highlight-words'
import {
  EyeOutlined,
  EditOutlined,
  CheckCircleOutlined,
  CloseCircleFilled,
  SearchOutlined,
  FilterOutlined,
  ClearOutlined,
  CloseCircleOutlined,
  ReloadOutlined,
  LoadingOutlined,
  StarFilled,
  StarOutlined
} from '@ant-design/icons'
import type { REPORT } from 'types'
import { PageTitle } from 'components'
import type { ColumnType, ColumnsType } from 'antd/es/table'
import { userListClientReports } from 'hooks/userListClientReports'
type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

interface DataType {
  key: 'report_name' | 'report_desc' | 'view' | 'access_level' | 'group_name'
  report_name: string
  report_desc: string
  access_level: string
  group_name: string
  view: any
  sorter?: (a: any, b: any) => number
  responsive?: Breakpoint[]
  render?: (text: any, report: any) => JSX.Element
  width?: number
}
type DataIndex = keyof DataType

const ListClientReports: React.FC = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#d67632' }} spin />
  const {
    favorites,
    screenWidth,
    miniLoading,
    loading,
    refreshing,
    reports,
    searchInput,
    searchText,
    searchedColumn,
    handleSearch,
    handleReset,
    handleRefresh,
    handleSaveReportName,
    handleSaveReportDescription,
    handleCancel,
    startEditing,
    handleFavorite,
    editingReportNameKey,
    editedReportNameValue,
    setEditedReportNameValue,
    editingReportDescKey,
    editedReportDescValue,
    setEditedReportDescValue,
    navigate,
    setEditingReportDescKey,
    setEditingReportNameKey,
    setSearchText,
    setSearchedColumn
  } = userListClientReports()

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<REPORT> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close
    }) => (
      <div
        style={{ padding: 8 }}
        onKeyDown={(e) => {
          e.stopPropagation()
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }}
          onPressEnter={() => {
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            className="button"
            type="primary"
            onClick={() => {
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            className="button"
            type="primary"
            onClick={() => {
              clearFilters && handleReset(clearFilters)
            }}
            icon={<ClearOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            icon={<FilterOutlined />}
            style={{ color: '#d67632' }}
            onClick={() => {
              confirm({ closeDropdown: false })
              setSearchText((selectedKeys as string[])[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            icon={<CloseCircleOutlined />}
            style={{ color: '#d67632' }}
            onClick={() => {
              close()
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#d67632' : undefined }} />
    ),
    onFilter: (value, record: any) => {
      return record && dataIndex && record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase())
        : false
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex
        ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
          )
        : (
            text
          )
  })

  const renderReportNameSuffixButton = <>
    {miniLoading && (<Button
      icon={<CheckCircleOutlined style={{ color: 'green' }} />}
      loading={miniLoading} />)}
    <CloseCircleFilled
      style={{ marginLeft: 8, color: 'red', cursor: 'pointer' }}
      onClick={() => {
        setEditingReportNameKey(null)
        setEditedReportNameValue('')
      } } />
  </>

  const renderReportDescriptionSuffixButton = <>
    {miniLoading && (<Button
      icon={<CheckCircleOutlined style={{ color: 'green' }} />}
      loading={miniLoading } />)}
    <CloseCircleFilled
      style={{
        marginLeft: 8,
        color: '#d67632',
        cursor: 'pointer'
      }}
      onClick={() => {
        setEditingReportDescKey(null)
        setEditedReportDescValue('')
      } } />
  </>

  const columns: ColumnsType<REPORT> = [
    {
      title: 'Favorite',
      key: 'favorite',
      render: (text, report) => (
        <Button
          type="link"
          icon={favorites.has(report.report_id) ? <StarFilled style={{ color: '#d67632' }} /> : <StarOutlined style={{ color: '#d67632' }} />}
          onClick={() => { handleFavorite(report.report_id) }}
        />
      ),
      sorter: (a, b) => {
        const aIsFavored = favorites.has(a.report_id)
        const bIsFavored = favorites.has(b.report_id)
        if (aIsFavored && !bIsFavored) {
          return -1
        } else if (!aIsFavored && bIsFavored) {
          return 1
        } else {
          return 0
        }
      },
      width: 80,
      responsive: ['lg'],
      align: 'center'
    },
    {
      title: 'Report Name',
      dataIndex: 'report_name',
      key: 'report_name',
      sorter: (a, b) => a.report_name.localeCompare(b.report_name),
      ...getColumnSearchProps('report_name'),
      render: (text, report) => {
        if (editingReportNameKey === report.report_id) {
          return (
            <Input
              autoFocus
              maxLength={35}
              value={editedReportNameValue}
              onChange={(e) => {
                setEditedReportNameValue(e.target.value)
              }}
              onPressEnter={async () => {
                void handleSaveReportName()
              }}
              onBlur={() => {
                handleCancel()
              }}
              suffix={
                renderReportNameSuffixButton
               }
            />
          )
        }
        return (
          <div
            onClick={() => {
              startEditing(report.report_id, 'report_name', text)
            }}
          >
            {text || 'Edit'}
            <EditOutlined style={{ marginLeft: 8, color: '#d67632' }} />
          </div>
        )
      }
    },
    {
      title: 'Description',
      dataIndex: 'report_desc',
      key: 'report_desc',
      responsive: ['lg'],
      ...getColumnSearchProps('report_desc'),
      sorter: (a, b) => a.report_desc.localeCompare(b.report_desc),
      render: (text, report) => {
        console.log('This is the editing key', editingReportDescKey)
        if (editingReportDescKey === report.report_id) {
          return (
            <Input
            autoFocus
            maxLength={75}
              value={editedReportDescValue}
              onChange={(e) => {
                setEditedReportDescValue(e.target.value)
              }}
              onPressEnter={async () => {
                void handleSaveReportDescription()
              }}
              onBlur={() => {
                handleCancel()
              }}
              suffix={
                renderReportDescriptionSuffixButton
              }
            />
          )
        }
        return (
          <span
            onClick={() => {
              startEditing(report.report_id, 'report_desc', text)
            }}
          >
            {text}
            <EditOutlined
              style={{ marginLeft: 8, color: '#d67632', cursor: 'pointer' }}
            />
          </span>
        )
      }
    },
    {
      title: 'Access Via',
      dataIndex: 'access_level',
      key: 'access_level',
      responsive: ['lg'],
      ...getColumnSearchProps('access_level'),
      sorter: (a, b) => a.access_level.localeCompare(b.access_level),
      render: (type?: string) => <Tag>{type ? type.toUpperCase() : 'UNKNOWN'}</Tag>
    },
    {
      title: 'Name',
      dataIndex: 'group_name',
      key: 'group_name',
      responsive: ['lg'],
      ...getColumnSearchProps('group_name'),
      sorter: (a, b) => a.group_name.localeCompare(b.group_name),
      render: (type?: string) => <Tag>{type ? type.toUpperCase() : 'N/A'}</Tag>
    },
    {
      title: 'View',
      key: 'view',
      render: (text, report) => (
        <Button
        block
            className="button"
            type="primary"
            onClick={() => {
              navigate(`/report/${report.report_id}`)
            }}
            icon={<EyeOutlined />}
            size="small"
            style={{ width: 130 }}
          >
            View Report
          </Button>
      )
    }
  ]

  const renderTableTitle = () => (
    <div
      style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', cursor: 'pointer' }}
      onClick={handleRefresh}
    >
      {
      refreshing
        ? (<Spin indicator={antIcon} spinning={refreshing}/>)
        : (<ReloadOutlined style={{ fontSize: '16px', marginRight: '10px', color: '#d67632' }} />)}
      <span style={{ color: '#d67632' }}>Refresh</span>
    </div>
  )
  return (
    <div style={{ padding: '0 20px' }}>
      <PageTitle text="Available Reports" color="#ffffff" />
      {loading
        ? (
        <Spin />
          )
        : (
        <Table
        title={renderTableTitle}
          dataSource={reports?.reports ?? []}
          columns={columns}
          bordered
          expandable={
            screenWidth <= 768
              ? {
                  expandedRowRender: (report) => (
                    <>
                      <p style={{ margin: 0 }}>{report.report_desc}</p>
                    </>
                  ),
                  rowExpandable: (report) => report.expandable !== 'No'
                }
              : undefined
          }
          rowKey="report_id"
          pagination={{ position: ['topRight'], pageSize: 5, responsive: true }}
          scroll={{ x: 'max-content' }}
        />
          )}
    </div>
  )
}

export default ListClientReports
