import React from 'react'

interface PageTitleProps {
  text: string
  color?: string
}

const PageTitle: React.FC<PageTitleProps> = ({ text, color }) => {
  return (
        <div style={styles.container}>
            <h2 style={{ ...styles.title, color: color ?? styles.title.color }}>{text}</h2>
            <div style={styles.underline}></div>
        </div>
  )
}

const styles = {
  container: {
    fontFamily: '"Roboto", sans-serif',
    display: 'inline-block',
    position: 'relative' as 'relative',
    whiteSpace: 'nowrap' as 'nowrap',
    overflow: 'hidden' as 'hidden',
    textOverflow: 'ellipsis' as 'ellipsis',
    maxWidth: '95%'
  },
  title: {
    fontSize: '2.2em',
    fontWeight: 700,
    marginBottom: '0.2em',
    color: '#2C3E50'
  },
  underline: {
    width: '70%',
    height: '5px',
    background: 'linear-gradient(45deg, #3498DB, #E74C3C)',
    position: 'absolute' as 'absolute',
    bottom: 0,
    left: '15%'
  }
}

export default PageTitle
