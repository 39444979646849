import React from 'react'
import { Modal, Table } from 'antd'
import type { ORG_MEMBER_DT } from 'types'
import { PageTitle } from 'components'
type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

interface GroupColumn {
  key: string
  title: string
  dataIndex: string
  sorter?: (a: any, b: any) => number
  responsive?: Breakpoint[]
  render?: (text: any, report: any) => JSX.Element
}

interface UsersModalProps {
  visible: boolean
  userOrgs: ORG_MEMBER_DT[]
  onClose: () => void
}

const UserOrgsModal: React.FC<UsersModalProps> = ({ visible, userOrgs, onClose }) => {
  const userReportsColumns: GroupColumn[] = [
    {
      title: 'Propman Org ID',
      dataIndex: 'propman_id',
      key: 'propman_id',
      sorter: (a, b) => a.propman_id.localeCompare(b.propman_id)
    }
  ]

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
    >
      <PageTitle text="User's Propman Organizations" />
      <Table
        dataSource={userOrgs}
        columns={userReportsColumns}
        rowKey="propman_id"
        bordered
        pagination={{ pageSize: 10, responsive: true }}
        scroll={{ x: 'max-content' }}
      />
    </Modal>
  )
}

export default UserOrgsModal
