import React, { createContext, useContext, useEffect, useReducer } from 'react'
import type {
  ClientsReportContextProps,
  ReportProviderProps,
  ClientsReportsState
} from 'types'
import { clientsReportsReducers } from 'reducers'
import { UserContext } from './UserContext'
import { listClientReport } from 'services'
import { CLIENTS_REPORTS_ACTION_TYPE_ENUMS } from 'appConstants'

const ClientsReportsContext = createContext<ClientsReportContextProps | undefined>(undefined)

const ClientsReportsProvider: React.FC<ReportProviderProps> = ({ children }) => {
  const userContext = useContext(UserContext)
  const initialState: ClientsReportsState = {
    clientReport: {
      count: 0,
      last_evaluated_key: null,
      reports: []
    }
  }
  const [state, dispatch] = useReducer(clientsReportsReducers, initialState)

  useEffect(function () {
    const removeListener = async function () {
      if (userContext) {
        const { state: userState } = userContext
        if (userState.isAuthenticated && userState.user && userState.user?.email_verified) {
          const clientReport = await listClientReport()
          dispatch({ type: CLIENTS_REPORTS_ACTION_TYPE_ENUMS.LIST_CLIENT_REPORTS, payload: clientReport })
        }
      }
    }

    void removeListener()
  }, [dispatch, userContext])

  return (
    <ClientsReportsContext.Provider value={{ state, dispatch }}>
      {children}
    </ClientsReportsContext.Provider>
  )
}

export { ClientsReportsContext, ClientsReportsProvider }
