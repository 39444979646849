import { Modal, Spin } from 'antd'
import React from 'react'

interface PleaseWaitOverlayProps {
  visible: boolean
}
const PleaseWaitOverlay: React.FC<PleaseWaitOverlayProps> = ({ visible }) => {
  return (
    <Modal
      open={visible}
      footer={
        <div style={{ textAlign: 'center', color: '#d67632' }}>
          One moment please...
        </div>
      }
      closable={false}
      centered
      bodyStyle={{ textAlign: 'center', padding: '24px' }}
      style={{ top: '10%' }}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
    >
      <Spin size="large" />
    </Modal>
  )
}

export default PleaseWaitOverlay
