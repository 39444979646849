import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import './styles/index.css'
import '@aws-amplify/ui-react/styles.css'
import { reportWebVitals } from './config/reportWebVitals'
import App from './App'
import { ampConfig } from './config'
import { Amplify } from 'aws-amplify'
Amplify.configure(ampConfig)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <StrictMode>
    <App />
  </StrictMode>
)

reportWebVitals()
