import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { ROUTES } from 'config/index'
import { UserContext } from 'context'

interface ProtectedRouteProps {
  children: React.ReactNode
  requireAdmin?: boolean
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, requireAdmin = false }) => {
  const userContext = useContext(UserContext)
  const userData = userContext?.state
  const isAuthenticated = userData?.isAuthenticated

  if (!isAuthenticated) {
    return <Navigate to={ROUTES.LOGIN} />
  }
  console.log('userData', userData)
  if (requireAdmin && !userData?.user?.userIsAdmin) {
    alert('Does not look like you have a door here')
    return <Navigate to={ROUTES.HOME} />
  }

  return <>{children}</>
}

export default ProtectedRoute
