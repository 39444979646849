// UpdateUser.tsx
import React, { useState, useEffect } from 'react'
import { Modal, Form, Select, Input, Button, Typography, notification } from 'antd'
import type { COGNITO_GRP_DT, USER_DT } from 'types'
import { listCognitoUserGroups, listUsers, updateUserAttributes } from 'services'
import { Disclaimer, PageTitle } from 'components'
import { useNotifications } from 'hooks'
import { trimWhitespace } from 'utils'
const { Title, Text } = Typography
const { Option } = Select

const UpdateUser: React.FC = () => {
  const { addNotification } = useNotifications()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState<USER_DT[]>([])
  const [user, setUser] = useState<USER_DT | null>(null)
  // const [userGroups, setUserGroups] = useState<COGNITO_GRP_DT[]>([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const listOfUsers = await listUsers()
        if (Array.isArray(listOfUsers)) {
          setUsers(listOfUsers)
        }
      } catch (error) {
        try {
          addNotification({
            id: Date.now(),
            type: 'error',
            message: `${error}`,
            timestamp: Date.now()
          })
        } catch (innerError) {
          console.log(innerError)
        }
      } finally {
        setLoading(false)
      }
      //   try {
      //     const groups = await listCognitoUserGroups()
      //     if (Array.isArray(groups)) {
      //       setUserGroups(groups)
      //     }
      //   } catch (error) {
      //     console.error('Error fetching the users groups', error)
      //   }
    }

    void fetchData()
  }, [])

  const handleSelected = (selected: USER_DT) => {
    console.log('Selected Item is: ', selected)
    setUser(selected)
    form.setFieldsValue({
      user: JSON.stringify(selected), // Setting user in the form
      given_name: selected.given_name,
      family_name: selected.family_name,
      email: selected.email
    })
  }

  const onFinish = async (values: any) => {
    setLoading(true)
    try {
      //   const userGroupName: string = (JSON.parse(values.user_group)).group_name
      const data = (JSON.parse(values.user))
      const payload = {
        ...data,
        given_name: values.given_name,
        family_name: values.family_name,
        email: values.email
      }

      Modal.confirm({
        title: 'Do you really want to update this user attributes?',
        content: `User Name: ${data.given_name} ${data.family_name} will be changed to ${values.given_name} ${values.family_name}`,
        onOk: async () => {
          try {
            setLoading(true)
            const msg = await updateUserAttributes(payload)

            notification.success({
              message: 'User Updated Successfully',
              description: msg ?? `User ${data.given_name} ${data.family_name} has been updated successfully.`
            })
            setUser(null)
            form.resetFields()
          } catch (error: any) {
            try {
              addNotification({
                id: Date.now(),
                type: 'error',
                message: `${error}`,
                timestamp: Date.now()
              })
            } catch (innerError) {
              console.log(innerError)
            }
            notification.error({
              message: 'Update User Failed',
              description: error.message || 'There was an error updating the user.'
            })
          } finally {
            setLoading(false)
          }
        }
      })
    } catch (error: any) {
      try {
        addNotification({
          id: Date.now(),
          type: 'error',
          message: `${error}`,
          timestamp: Date.now()
        })
      } catch (innerError) {
        console.log(innerError)
      }
      notification.error({
        message: 'Update User Failed',
        description: error.message || 'There was an error updating the user.'
      })
    } finally {
      setLoading(false)
    }
  }

  return (
        <div>
            <PageTitle text="Update User Attributes" />
            {/* <Title level={4}>Note: Group changes affect permissions. Any group you select here will be added to existing groups. If you need to remove user from a group or groups, please use the remove user menu</Title> */}
            <Form
                form={form}
                name="update-user"
                onFinish={onFinish}
                layout="vertical"
                style={{ maxWidth: '400px' }}
            >
                          <Disclaimer
                message="Use this utility to update the user's given and family name."
            />
                <Form.Item
                    label=" Select User"
                    name="user"
                    rules={[{ required: true, message: 'Please select a user!' }]}
                >
                    <Select
                        placeholder="Select a user"
                        showSearch
                        loading={loading}
                        onSelect={(value) => { handleSelected(JSON.parse(value)) }}
                        value={user ? JSON.stringify(user) : undefined}
                    >
                        {users.map((selectedUser) => (
                            <Option key={selectedUser.sub} value={JSON.stringify(selectedUser)}>
                                {`${selectedUser.given_name} ${selectedUser.family_name}`}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    getValueFromEvent={trimWhitespace}
                    rules={[
                      { required: true, message: 'Please input the email!' },
                      { type: 'email', message: 'The input is not valid E-mail!' }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="First Name"
                    name="given_name"
                    getValueFromEvent={trimWhitespace}
                    rules={[{ required: true, message: 'Please input the given name!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Last Name"
                    name="family_name"
                    getValueFromEvent={trimWhitespace}
                    rules={[{ required: true, message: 'Please input the last name!' }]}
                >
                    <Input />
                </Form.Item>
                {/* <Form.Item
          label="Select a Users Group"
          name="user_group"
          rules={[{ required: true, message: 'Please select a users group!' }]}
        >
          <Select showSearch placeholder="Select a users group">
            {userGroups.map(group => <Option key={group.group_name} value={JSON.stringify(group)}>{`${group.group_name} | ${group.group_desc}`}</Option>)}
          </Select>
        </Form.Item> */}
                <Form.Item>
                    <Button className='button' type="primary" htmlType="submit" loading={loading}>
                        Update User
                    </Button>
                </Form.Item>
            </Form>
        </div>
  )
}

export default UpdateUser
