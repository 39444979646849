import { ADMINS_REPORTS_ACTION_TYPE_ENUMS } from 'appConstants'
import { type AdminsReportsState, type AdminsReportsAction } from 'types'

export const adminsReportReducer = (state: AdminsReportsState, action: AdminsReportsAction): AdminsReportsState => {
  console.log('Here is the dispatched', state, action)
  switch (action.type) {
    case ADMINS_REPORTS_ACTION_TYPE_ENUMS.LIST_ADMIN_REPORTS:
      return {
        ...state,
        adminReport: action.payload
      }
    default:
      return state
  }
}
