import axios, { type AxiosInstance } from 'axios'
import { getIdToken } from 'utils'
import { apiConfig } from 'config/index'
// import { useNotifications } from './useNotifications'
const baseURL = apiConfig.endpoint

export const createAxiosInstance = (params = {}, headers = {}): AxiosInstance => {
//   const { addNotification } = useNotifications()
  const instance = axios.create({
    baseURL,
    timeout: 60000, // 1 minutes max
    headers: {
      'Content-Type': 'application/json',
      ...headers
    },
    ...params
  })

  instance.interceptors.request.use(async (config) => {
    try {
      const idToken = await getIdToken()
      config.headers.Authorization = idToken
    } catch (error) {
      console.error('Error attaching token', error)
    }
    return config
  })

  instance.interceptors.response.use(
    (response) => {
      return response
    },
    async (error) => {
      let errMsg = 'An unknown error occurred'
      if (error.response) {
        errMsg = error.response.data.message
      } else if (error.request) {
        errMsg = 'No response received from the server.'
      } else {
        errMsg = `Error setting up the request: ${error.message}`
      }
      //   try {
      //     addNotification({
      //       id: Date.now(), // or any other unique identifier mechanism you use
      //       type: 'error',
      //       message: errMsg,
      //       timestamp: Date.now()
      //     })
      //   } catch (error) {
      //     console.log(error)
      //   }
      return await Promise.reject(new Error(errMsg))
    }
  )

  return instance
}
