import { useEffect, useState, useRef, useCallback } from 'react'
import {
  notification,
  type InputRef,
  message
} from 'antd'
import { useNavigate } from 'react-router-dom'
import { listClientReport, updateReportAttributes } from 'services'
import type { CLIENT_REPORTS, REPORT } from 'types'
import type { FilterConfirmProps } from 'antd/es/table/interface'
type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

interface DataType {
  key: 'report_name' | 'report_desc' | 'view' | 'access_level' | 'group_name'
  report_name: string
  report_desc: string
  access_level: string
  group_name: string
  view: any
  sorter?: (a: any, b: any) => number
  responsive?: Breakpoint[]
  render?: (text: any, report: any) => JSX.Element
  width?: number
}
type DataIndex = keyof DataType
const FAVORITE_KEY = 'favorites'

export const userListClientReports = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const navigate = useNavigate()
  const [miniLoading, setMiniLoading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [refreshing, setRefreshing] = useState<boolean>(false)
  const [reports, setReports] = useState<CLIENT_REPORTS | null>(null)
  const searchInput = useRef<InputRef>(null)
  const [editingReportNameKey, setEditingReportNameKey] = useState<
  string | null
  >(null)
  const [editedReportNameValue, setEditedReportNameValue] =
    useState<string>('')
  const [editingReportDescKey, setEditingReportDescKey] = useState<
  string | null
  >(null)
  const [editedReportDescValue, setEditedReportDescValue] =
    useState<string>('')
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [favorites, setFavorites] = useState(new Set())
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setSearchText('')
  }

  const handleRefresh = () => {
    void refreshReport()
  }

  const handleSaveReportName = async () => {
    try {
      setMiniLoading(true)
      console.log('The edited value is ', editedReportNameValue)
      const data = {
        edited_key: 'report_name',
        edited_value: editedReportNameValue,
        edited_report_id: editingReportNameKey
      }
      console.log('The params value is ', data)
      const msg = await updateReportAttributes(data)
      setEditingReportNameKey(null)
      setEditedReportNameValue('')
      setReports((prevReports) => {
        if (!prevReports) return null

        const updatedReports: REPORT[] = JSON.parse(
          JSON.stringify(prevReports.reports)
        )
        const reportToUpdate = updatedReports.find(
          (report) => report.report_id === editingReportNameKey
        )

        if (reportToUpdate && editingReportNameKey && editedReportNameValue) {
          reportToUpdate.report_name = editedReportNameValue
          return {
            ...prevReports,
            reports: updatedReports
          }
        }

        return prevReports
      })
      void message.success(msg)
    } catch (error: any) {
      notification.error({
        message:
          error?.message ?? 'An error occurred trying to update report name'
      })
      console.log(error)
    } finally {
      setMiniLoading(false)
    }
  }

  const handleSaveReportDescription = async () => {
    try {
      setMiniLoading(true)
      const data = {
        edited_key: 'report_desc',
        edited_value: editedReportDescValue,
        edited_report_id: editingReportDescKey
      }
      const msg = await updateReportAttributes(data)
      setEditingReportDescKey(null)
      setEditedReportDescValue('')
      setReports((prevReports) => {
        if (!prevReports) return null

        const updatedReports: REPORT[] = JSON.parse(
          JSON.stringify(prevReports.reports)
        )
        const reportToUpdate = updatedReports.find(
          (report) =>
            report.report_id === editingReportDescKey
        )

        if (
          reportToUpdate &&
          editingReportDescKey &&
          editedReportDescValue
        ) {
          reportToUpdate.report_desc = editedReportDescValue
          return {
            ...prevReports,
            reports: updatedReports
          }
        }

        return prevReports
      })
      void message.success(msg)
    } catch (error: any) {
      notification.error({
        message:
          error?.message ??
          'An error occurred trying to update report description'
      })
      console.log(error)
    } finally {
      setMiniLoading(false)
    }
  }
  const handleCancel = () => {
    setEditingReportNameKey(null)
    setEditedReportNameValue('')
    setEditingReportDescKey(null)
    setEditedReportDescValue('')
  }

  const startEditing = (
    reportId: string,
    type: 'report_name' | 'report_desc',
    value: string
  ) => {
    if (type === 'report_name') {
      setEditingReportNameKey(reportId)
      setEditedReportNameValue(value)
    } else if (type === 'report_desc') {
      setEditingReportDescKey(reportId)
      setEditedReportDescValue(value)
    }
  }

  const handleFavorite = (reportId: string) => {
    setFavorites((prevFavorites) => {
      const newFavorites = new Set(prevFavorites)
      if (newFavorites.has(reportId)) {
        newFavorites.delete(reportId)
      } else {
        newFavorites.add(reportId)
      }
      return newFavorites
    })
  }

  useEffect(() => {
    // Load favorites from local storage when the component mounts
    const storedFavorites = localStorage.getItem(FAVORITE_KEY)
    if (storedFavorites) {
      setFavorites(new Set(JSON.parse(storedFavorites)))
    }
  }, [])

  useEffect(() => {
    // Save favorites to local storage when `favorites` state changes
    localStorage.setItem(FAVORITE_KEY, JSON.stringify([...favorites]))
  }, [favorites])

  const refreshReport = useCallback(async () => {
    try {
      setRefreshing(true)
      const clientReport = await listClientReport()

      if (clientReport) {
        setReports(clientReport)

        if (clientReport.count === 1 && favorites.size === 1) {
          navigate(`/report/${clientReport.reports[0].report_id}`)
        }
      }
    } catch (error: any) {
      console.error('Error fetching reports:', error)
      notification.error({
        message: 'Error',
        description: error.message || 'Failed to fetch reports.'
      })
    } finally {
      setRefreshing(false)
    }
  }, [])

  const fetchReports = useCallback(async () => {
    try {
      setLoading(true)
      const clientReport = await listClientReport()

      if (clientReport) {
        setReports(clientReport)

        if (clientReport.count === 1 && favorites.size === 1) {
          navigate(`/report/${clientReport.reports[0].report_id}`)
        }
      }
    } catch (error: any) {
      console.error('Error fetching reports:', error)
      notification.error({
        message: 'Error',
        description: error.message || 'Failed to fetch reports.'
      })
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    void fetchReports()
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return {
    favorites,
    screenWidth,
    miniLoading,
    loading,
    refreshing,
    reports,
    searchInput,
    searchText,
    searchedColumn,
    handleSearch,
    handleReset,
    handleRefresh,
    handleSaveReportName,
    handleSaveReportDescription,
    handleCancel,
    startEditing,
    handleFavorite,
    editingReportNameKey,
    editedReportNameValue,
    setEditedReportNameValue,
    editingReportDescKey,
    editedReportDescValue,
    setEditedReportDescValue,
    navigate,
    setEditingReportDescKey,
    setEditingReportNameKey,
    setSearchText,
    setSearchedColumn
  }
}
