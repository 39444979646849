import React, { createContext, useEffect, useReducer } from 'react'
import { Hub } from 'aws-amplify'
import type { UserState, UserProviderProps, UserContextProps, USER_DT } from 'types'
import { userReducer } from 'reducers'
import { USER_ACTION_TYPE_ENUMS } from 'appConstants'
import { getUser } from 'services'
const dummyDispatch = () => {} // A no-op function.

const defaultContextValue: UserContextProps = {
  state: {
    isAuthenticated: false
    // ... any other initial properties that UserState might have ...
  },
  dispatch: dummyDispatch
}

const UserContext = createContext<UserContextProps>(defaultContextValue)

const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const initialState: UserState = {
    isAuthenticated: false
  }

  const [state, dispatch] = useReducer(userReducer, initialState)

  useEffect(() => {
    const removeListener = Hub.listen('auth', async ({ payload }) => {
      const { event, data } = payload
      console.log('This is the Hub', event, data)
      switch (event) {
        case USER_ACTION_TYPE_ENUMS.AUTHENTICATED:
          try {
            const serviceUserData: USER_DT = await getUser()
            dispatch({ type: USER_ACTION_TYPE_ENUMS.AUTHENTICATED, user: serviceUserData })
            break
          } catch (error) {
            break
          }
        case USER_ACTION_TYPE_ENUMS.SIGN_OUT:
          dispatch({ type: USER_ACTION_TYPE_ENUMS.SIGN_OUT, user: null })
          break
        case USER_ACTION_TYPE_ENUMS.AUTHENTICATION_ERROR:
          dispatch({ type: USER_ACTION_TYPE_ENUMS.AUTHENTICATION_ERROR, error: data })
          break
        default:
          break
      }
    })

    return () => { removeListener() }
  }, [dispatch])

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {children}
    </UserContext.Provider>
  )
}

export { UserContext, UserProvider }
