import React from 'react'
import { Modal, Table } from 'antd'
import type { ORG_MEMBER_DT } from 'types'
import { PageTitle } from 'components'
type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

interface GroupColumn {
  key: string
  title: string
  dataIndex: string
  sorter?: (a: any, b: any) => number
  responsive?: Breakpoint[]
  render?: (text: any, report: any) => JSX.Element
}

interface UsersModalProps {
  visible: boolean
  orgMembers: ORG_MEMBER_DT[]
  onClose: () => void
}

const OrgMembershipModal: React.FC<UsersModalProps> = ({ visible, orgMembers, onClose }) => {
  const userReportsColumns: GroupColumn[] = [
    {
      title: 'Given Name',
      dataIndex: 'given_name',
      key: 'given_name',
      sorter: (a, b) => a.given_name.localeCompare(b.given_name)
    },
    {
      title: 'Family Name',
      dataIndex: 'family_name',
      key: 'family_name',
      responsive: ['lg']
    }
  ]

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
    >
      <PageTitle text="Organization Members" />
      <Table
        dataSource={orgMembers}
        columns={userReportsColumns}
        rowKey="sub"
        bordered
        pagination={{ pageSize: 10, responsive: true }}
        scroll={{ x: 'max-content' }}
      />
    </Modal>
  )
}

export default OrgMembershipModal
