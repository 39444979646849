import React, { useEffect, useRef, useState } from 'react'
import type { PROPMAN_ORGS, RLS_TABLE } from 'types'
import { PageTitle } from 'components'
import { useNotifications } from 'hooks'
import Highlighter from 'react-highlight-words'
import { Button, Input, Space, Table, Spin, notification, type InputRef } from 'antd'
import type { FilterConfirmProps } from 'antd/es/table/interface'
import type { ColumnType, ColumnsType } from 'antd/es/table'
import { getRlsTableRecords } from 'services'
import { SearchOutlined, FilterOutlined, ClearOutlined, CloseCircleOutlined } from '@ant-design/icons'
// '_master_propmanid': propman_id,
// 'Email': Email,
// 'Name': Name
type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
interface DataType {
  key: string
  UserName: string
  GroupName: string
  _master_propmanid: string
  Email: string
  Name: string
  sorter?: (a: any, b: any) => number
  responsive?: Breakpoint[]
  render?: (text: any, report: any) => JSX.Element
}
type DataIndex = keyof DataType

const ListRlsTable: React.FC = () => {
  const { addNotification } = useNotifications()
  const [loading, setLoading] = useState(false)
  const [rlsTableRecords, setRlsTableRecords] = useState<RLS_TABLE[]>([])
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef<InputRef>(null)

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<RLS_TABLE> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => { e.stopPropagation() }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => { setSelectedKeys(e.target.value ? [e.target.value] : []) }}
                    onPressEnter={() => { handleSearch(selectedKeys as string[], confirm, dataIndex) }}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        className='button'
                        type="primary"
                        onClick={() => { handleSearch(selectedKeys as string[], confirm, dataIndex) }}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        className='button'
                        type="primary"
                        onClick={() => { clearFilters && handleReset(clearFilters) }}
                        icon={<ClearOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        icon={<FilterOutlined />}
                        style={{ color: '#d67632' }}
                        onClick={() => {
                          confirm({ closeDropdown: false })
                          setSearchText((selectedKeys as string[])[0])
                          setSearchedColumn(dataIndex)
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        icon={<CloseCircleOutlined />}
                        style={{ color: '#d67632' }}
                        onClick={() => {
                          close()
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
    ),
    filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#d67632' : undefined }} />
    ),
    onFilter: (value, record: any) => {
      return (record && dataIndex && record[dataIndex])
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase())
        : false
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex
        ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
          )
        : (
            text
          )
  })

  const columns: ColumnsType<RLS_TABLE> = [
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'Name',
      sorter: (a: any, b: any) => a.Name.localeCompare(b.Name),
      ...getColumnSearchProps('Name')
    },
    {
      title: 'Email',
      dataIndex: 'Email',
      key: 'Email',
      sorter: (a: any, b: any) => a.Email.localeCompare(b.Email),
      ...getColumnSearchProps('Email')
    },
    {
      title: 'User ID',
      dataIndex: 'UserName',
      key: 'UserName',
      sorter: (a: any, b: any) => a.UserName.localeCompare(b.UserName),
      ...getColumnSearchProps('UserName')
    },
    {
      title: 'Group Name',
      dataIndex: 'GroupName',
      key: 'GroupName',
      sorter: (a: any, b: any) => a.GroupName.localeCompare(b.GroupName),
      ...getColumnSearchProps('GroupName')
    },
    {
      title: 'PropmanID',
      dataIndex: '_master_propmanid',
      key: '_master_propmanid',
      sorter: (a: any, b: any) => a._master_propmanid.localeCompare(b._master_propmanid),
      ...getColumnSearchProps('_master_propmanid')
    }
  ]

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const listOfRlsRecords = await getRlsTableRecords()
        setRlsTableRecords(listOfRlsRecords.reports)
      } catch (error) {
        try {
          addNotification({
            id: Date.now(),
            type: 'error',
            message: `${error}`,
            timestamp: Date.now()
          })
        } catch (innerError) {
          console.log(innerError)
        }
        notification.error({
          message: 'Error getting rls table records',
          description: `${error}.`
        })
      } finally {
        setLoading(false)
      }
    }

    void fetchData()
  }, [])

  return (
        <div>
            <PageTitle text="RLS Table for Quicksight Permission Management" />
            {loading
              ? (
                    <Spin />
                )
              : (
                    <>
                        <Table dataSource={rlsTableRecords} bordered columns={columns} rowKey="id" /></>
                )}
        </div>
  )
}

export default ListRlsTable
