export const ROUTES = {
  HOME: '/',
  LANDING_PAGE: '/home',
  LOGIN: '/login',
  VERIFY_EMAIL: '/verify-email',
  RESET_PASSWORD: '/reset-password',
  ADMIN: '/admin',
  DASHBOARD: '/dashboards',
  REPORT: '/report/:report_id',
  NOTFOUND: '*',
  ERROR: '/error'
}
