import { CLIENTS_REPORTS_ACTION_TYPE_ENUMS } from 'appConstants'
import { type ClientsReportsState, type ClientsReportsAction } from 'types'

export const clientsReportsReducers = (state: ClientsReportsState, action: ClientsReportsAction): ClientsReportsState => {
  console.log('Here is the dispatched clientsReportsReducers', state, action)
  switch (action.type) {
    case CLIENTS_REPORTS_ACTION_TYPE_ENUMS.LIST_CLIENT_REPORTS:
      return {
        ...state,
        clientReport: action.payload
      }
    default:
      return state
  }
}
