import React, { useState } from 'react'
import { Tag, Button, Checkbox, Modal, Popconfirm, Table, message, Space } from 'antd'
import { type UserOrGroupReport, type REPORT } from 'types'
import { PageTitle } from 'components'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import { useNotifications } from 'hooks'
import { deleteSelectedUserGroupReports } from 'services'
type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

interface ReportColumn {
  key: string
  title: string
  dataIndex: string
  sorter?: (a: any, b: any) => number
  responsive?: Breakpoint[]
  render?: (text: any, report: any) => JSX.Element
}

interface ReportsModalProps {
  visible: boolean
  reports: REPORT[]
  onClose: () => void
}

const GroupReportsModal: React.FC<ReportsModalProps> = ({ visible, reports, onClose }) => {
  const [loading, setLoading] = useState(false)
  const { addNotification } = useNotifications()
  async function onDelete (report: any) {
    try {
      setLoading(true)
      const msg = await deleteSelectedUserGroupReports([report])
      void message.success(msg || `${report.report_name} removed successfully`)
      onClose()
    } catch (error: any) {
      addNotification({
        id: Date.now(),
        type: 'error',
        message: `${error}`,
        timestamp: Date.now()
      })
      void message.error(error?.message ?? 'There was an error deleting the selected reports')
    } finally {
      setLoading(false)
    }
  }
  const [selectedReports, setSelectedReports] = useState<UserOrGroupReport[]>([])
  const handleSelect = (e: CheckboxChangeEvent, selectedReport: UserOrGroupReport) => {
    if (e.target.checked) {
      setSelectedReports(prev => [...prev, selectedReport])
    } else {
      setSelectedReports(prev => prev.filter(report => report.report_id !== selectedReport.report_id))
    }
  }
  const userReportsColumns: ReportColumn[] = [
    {
      key: 'id',
      title: 'Select',
      dataIndex: 'id',
      render: (_: any, record: any) => <Checkbox style={{
      }} onChange={e => { handleSelect(e, record) }} />
    },
    {
      title: 'Report Name',
      dataIndex: 'report_name',
      key: 'report_name',
      sorter: (a, b) => a.report_name.localeCompare(b.report_name)
    },
    {
      title: 'Description',
      dataIndex: 'report_desc',
      key: 'description',
      responsive: ['lg']
    },
    {
      title: 'Access Via',
      dataIndex: 'access_level',
      key: 'access_level',
      responsive: ['lg'],
      render: (type?: string) => <Tag>{type ? type.toUpperCase() : 'USER_GROUP'}</Tag>
    },
    {
      title: 'Remove',
      key: 'delete',
      dataIndex: 'delete',
      render: (_, report) => (
        <Popconfirm
          title="You want to remove this report from the group's list?"
          onConfirm={async () => { await onDelete(report) }}
          okText="Yes"
          okButtonProps={{
            className: 'ant-popover-buttons ant-popover-button',
            danger: true,
            loading
          }}
          cancelText="No"
          cancelButtonProps={{
            className: 'ant-popover-buttons ant-popover-button'
          }}
        >
          <Button type="link" danger >Remove</Button>
        </Popconfirm>
      )
    }
  ]

  async function handleDeleteSelectedReportsFromUserGroup (): Promise<void> {
    if (selectedReports.length <= 0) {
      void message.error('Select report to remove')
    } else {
      try {
        setLoading(true)
        const msg = await deleteSelectedUserGroupReports(selectedReports)
        void message.info(msg)
        onClose()
      } catch (error: any) {
        addNotification({
          id: Date.now(),
          type: 'error',
          message: `${error}`,
          timestamp: Date.now()
        })
        void message.error(error?.message ?? 'There was an error deleting the selected reports')
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
    >
      <PageTitle text="Group Quicksight Reports" />
      {
                (selectedReports && selectedReports.length > 0)
                  ? <>
                        <Space>
                            <Button loading={loading} type="link" danger onClick={handleDeleteSelectedReportsFromUserGroup}>Remove</Button>
                        </Space>
                    </>
                  : null
            }
      <Table
        dataSource={reports}
        columns={userReportsColumns}
        rowKey="user_group_id"
        bordered
        pagination={{ pageSize: 10, responsive: true }}
        scroll={{ x: 'max-content' }}
      />
    </Modal>
  )
}

export default GroupReportsModal
