// AppRoutes.tsx
import { Route, Routes } from 'react-router-dom'
import {
  AdminPortal,
  ClientPortal,
  HomePage,
  NotFoundPage,
  ViewReport,
  LoginPage
} from 'components/index'
import ProtectedRoute from 'components/pages/auth/ProtectedRoute'
import { ROUTES } from 'config/index'
import { AdminsReportsProvider, ClientsReportsProvider } from 'context'
import PasswordReset from 'components/pages/auth/PasswordReset'
import { VerifyEmailPage } from 'components/pages/auth/VerifyEmailPage'
import ErrorPage from 'components/pages/core/ErrorPage'
const AppRoutes: React.FC = () => (
    <Routes>
        <Route path={ROUTES.HOME} element={<LoginPage />} />
        <Route path={ROUTES.LANDING_PAGE} element={<HomePage />} />
        <Route path={ROUTES.LOGIN} element={<LoginPage />} />
        <Route path={ROUTES.RESET_PASSWORD} element={<PasswordReset />} />
        <Route path={ROUTES.ERROR} element={<ErrorPage />} />
        <Route
            path={ROUTES.ADMIN}
            element={
                <AdminsReportsProvider>
                    <ProtectedRoute requireAdmin={true}>
                        <AdminPortal />
                    </ProtectedRoute>
                </AdminsReportsProvider>
            }
        />
        <Route
            path={ROUTES.VERIFY_EMAIL}
            element={
                    <ProtectedRoute >
                        <VerifyEmailPage />
                    </ProtectedRoute>
            }
        />
        <Route
            path={ROUTES.DASHBOARD}
            element={
                <ClientsReportsProvider>
                    <ProtectedRoute>
                        <ClientPortal />
                    </ProtectedRoute>
                </ClientsReportsProvider>
            }
        />
        <Route
            path={ROUTES.REPORT}
            element={
                <ProtectedRoute>
                    <ViewReport />
                </ProtectedRoute>
            }
        />
        <Route path={ROUTES.NOTFOUND} element={<NotFoundPage />} />
    </Routes>
)

export { AppRoutes }
