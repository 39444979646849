import React, { useState } from 'react'
import { Layout } from 'antd'
import ListClientReports from './ListClientReports'
import ClientNavbar from './ClientNavbar'

const { Content, Footer } = Layout

const COMPONENT_MAP: any = {
  listClientReports: <ListClientReports />
}

const ClientPortal: React.FC = () => {
  const [selectedKey, setSelectedKey] = useState<string>('listClientReports')

  const onMenuClick = (key: string) => {
    setSelectedKey(key)
  }

  return (
    <Layout className="client-layout cardsBody">
      <ClientNavbar onMenuClick={onMenuClick} selectedKey={selectedKey} />
      <Content className="site-layout-background">
        {COMPONENT_MAP[selectedKey] || <div className="not-implemented">Not implemented</div>}
      </Content>
      <Footer className="client-footer">
        ©2024 Grosvenor Systems Clients Portal. All Rights Reserved.
      </Footer>
    </Layout>
  )
}

export default ClientPortal
