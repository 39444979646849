import React, { useState, useRef, useContext, useEffect } from 'react'
import { Form, Checkbox, Button, Typography, Table, message, Input, type InputRef, Select } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { getOrganizationIDs, listDirectPowerBiReportsFromPwrBiService, uploadReport } from 'services'
import { useNotifications } from 'hooks'
import type { FormInstance } from 'antd/es/form'
import { PageTitle } from 'components'
import { type DIRECT_PWR_BI_RPT_DT, type ORG_ID } from 'types'
import { type CheckboxChangeEvent } from 'antd/es/checkbox'
const { Option } = Select
const EditableContext = React.createContext<FormInstance<any> | null>(null)

interface EditableRowProps {
  index: number
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}

const { Title } = Typography

interface ConvertedPowerBiDataType {
  key: string
  name: string
  report_desc: string
  dashboard_id: string
  created_time: string
  last_published_time: string
  published_version_number: number
  last_updated_time: string
  propman_id: string
}

interface EditableCellProps {
  title: React.ReactNode
  editable: boolean
  children: React.ReactNode
  dataIndex: keyof DIRECT_PWR_BI_RPT_DT
  record: DIRECT_PWR_BI_RPT_DT
  inputType: string
  index: number
  handleSave: (record: DIRECT_PWR_BI_RPT_DT) => void
}
const EditableCell: React.FC<EditableCellProps> = ({
  editable,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false)
  const inputRef = useRef<InputRef>(null)
  const form = useContext(EditableContext)

  useEffect(() => {
    if (editing && inputRef?.current) {
      inputRef.current.focus()
    }
  }, [editing])

  const toggleEdit = () => {
    setEditing(!editing)
    form?.setFieldsValue({ [dataIndex]: record[dataIndex] })
  }

  const save = async () => {
    try {
      const values = await form?.validateFields()

      toggleEdit()
      handleSave({ ...record, ...values })
    } catch (errInfo) {
      console.log('Save failed:', errInfo)
    }
  }

  let childNode = children

  if (editable) {
    childNode = editing
      ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`
          }
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
        )
      : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
        )
  }
  return <td {...restProps}>{childNode}</td>
}

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
type EditableTableProps = Parameters<typeof Table>[0]
interface DataType {
  key: string
  name: string
  reportType: string
  webUrl: string
  editable: boolean
  onCell?: (record: DIRECT_PWR_BI_RPT_DT) => void
  sorter?: (a: any, b: any) => number
  responsive?: Breakpoint[]
  render?: (text: any, report: any) => JSX.Element
}
type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>
const UploadReports: React.FC = () => {
  const [form] = Form.useForm()
  const { addNotification } = useNotifications()
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [loadingDownload, setLoadingDownload] = useState(false)
  const [reports, setReports] = useState<ConvertedPowerBiDataType[]>([])
  const [selectAll, setSelectAll] = useState(false)
  const [selectedReports, setSelectedReports] = useState<ConvertedPowerBiDataType[]>([])
  const [organizationID, setOrganizationID] = useState<ORG_ID[]>([])

  const handleOrgIDSelect = (value: any, key: any) => {
    const newData = [...reports]
    const index = newData.findIndex(item => key === item.key)
    newData[index] = { ...newData[index], propman_id: value }
    setReports(newData)
  }
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedReports([])
    } else {
      setSelectedReports(reports)
    }
    setSelectAll(!selectAll)
  }
  const convertPowerBiDataTypes = (data: DIRECT_PWR_BI_RPT_DT[]): ConvertedPowerBiDataType[] => {
    return data.map(item => ({
      key: item.DashboardId,
      name: item.Name,
      report_desc: 'Edit description',
      dashboard_id: item.DashboardId,
      created_time: item.CreatedTime,
      last_published_time: item.LastPublishedTime,
      last_updated_time: item.LastUpdatedTime,
      published_version_number: item.PublishedVersionNumber,
      propman_id: ''
    }))
  }
  const handleDelete = () => {
    const selectedReportIds = selectedReports.map(report => report.key)
    const newData = reports.filter(report => !selectedReportIds.includes(report.key))
    setReports(newData)
    setSelectedReports([])
  }
  const handleSelect = (e: CheckboxChangeEvent, selectedReport: ConvertedPowerBiDataType) => {
    if (e.target.checked) {
      setSelectedReports(prev => [...prev, selectedReport])
    } else {
      setSelectedReports(prev => prev.filter(report => report.dashboard_id !== selectedReport.dashboard_id))
    }
  }

  const defaultColumns: Array<ColumnTypes[number] & { editable?: boolean, dataIndex: string }> = [
    {
      title: <Checkbox checked={selectAll} onChange={handleSelectAll} />,
      dataIndex: 'select',
      render: (_: any, record: any) => {
        const typedRecord = record as ConvertedPowerBiDataType
        return (
        <Checkbox
          checked={selectedReports.includes(typedRecord)}
          onChange={(e) => { handleSelect(e, typedRecord) }}
        />
        )
      },
      responsive: ['xl']
    },
    {
      title: 'Dashboard ID',
      dataIndex: 'dashboard_id'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name)
    },
    {
      title: 'Report Description',
      dataIndex: 'report_desc',
      editable: true
    },
    {
      title: 'Created Time',
      dataIndex: 'created_time',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name)
    },
    {
      title: 'Last Published Time',
      dataIndex: 'last_published_time'
    },
    {
      title: 'Last Updated Time',
      dataIndex: 'last_updated_time'
    },
    {
      title: 'Published Version Number',
      dataIndex: 'published_version_number'
    },
    {
      title: 'Organization ID',
      dataIndex: 'orgID',
      render: (text, record) => (
        <Select
          showSearch
          placeholder="Select organization ID"
          onChange={(value) => { handleOrgIDSelect(value, record.key) }}
          value={record.orgIDSelected}
        >
          {organizationID.map(item => (
            <Option key={item.propman_id} value={item.propman_id}>
              <small>
                {item.status === 'Active' ? `${item.propman_id} | ${item.organization} | ${item.status}` : null}
              </small>
            </Option>
          ))}
        </Select>
      )
    }
  ]

  const fetchReports = async () => {
    try {
      setLoadingDownload(true)
      const orgIds: ORG_ID = await getOrganizationIDs()
      if (Array.isArray(orgIds)) {
        setOrganizationID(orgIds)
      }
    } catch (err) {
      console.log(err)
    }
    try {
      const response = await listDirectPowerBiReportsFromPwrBiService()
      const convertedData = convertPowerBiDataTypes(response)
      setReports(convertedData)
    } catch (error: any) {
      try {
        addNotification({
          id: Date.now(),
          type: 'error',
          message: error?.message || 'There was an error pulling the request from Quicksight. Please confirm with Power Admin if credentials have change.',
          timestamp: Date.now()
        })
      } catch (innerError) {
        console.log(innerError)
      }
      void message.error(error?.message || 'There was an error pulling the request from Quicksight. Please confirm with Power Admin if credentials have change.')
    } finally {
      setLoadingDownload(false)
    }
  }

  const handleUpload = async () => {
    try {
      const reportPayloadData: any = reports.map(item => {
        return {
          created_time: item.created_time,
          dashboard_id: item.dashboard_id,
          report_desc: item.report_desc,
          propman_id: item.propman_id,
          name: item.name,
          last_published_time:
          item.last_updated_time,
          last_update_time: item.last_updated_time,
          published_version_number: item.published_version_number
        }
      })
      setLoadingUpload(true)
      const response = await uploadReport(reportPayloadData)
      void message.success(response || 'Reports uploaded successfully.')
      form.resetFields()
      setReports([])
    } catch (error: any) {
      try {
        addNotification({
          id: Date.now(),
          type: 'error',
          message: `${error}`,
          timestamp: Date.now()
        })
      } catch (innerError) {
        console.log(innerError)
      }
      void message.error(error.message || 'There was an error uploading the reports.')
    } finally {
      setLoadingUpload(false)
    }
  }

  const handleSave = (row: DataType) => {
    const newData = [...reports]
    const index = newData.findIndex((item) => row.key === item.key)
    const item = newData[index]
    newData.splice(index, 1, {
      ...item,
      ...row
    })
    setReports(newData)
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  }

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave
      })
    }
  })
  return (
    <EditableContext.Provider value={form}>
    <div>
      <PageTitle text='Get and Upload Reports Directly from Quicksight'/>
      <Title level={4}>Use this utility to upload reports directly into the application</Title>
      <Title level={5}>1. First get the report from Quicksight</Title>
      <Title level={5}>2. Review it and add description</Title>
      <Title level={5}>3. Upload it to this application</Title>
      <Title level={5}>Note: If any of the reports are already in the app, it will simply be skipped.</Title>
      <Title level={5}>Note: You must select and delete reports that you do not intend to be part of the upload.</Title>
      <Form
      form={form}
        layout="vertical"
        style={{ maxWidth: '600px' }}
      >
        <Form.Item>
          <Button block className='button' type="primary" onClick={fetchReports} loading={loadingDownload}>
           Load Report From Quicksight
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
          className='button'
          block
          icon={<UploadOutlined />}
          onClick={handleUpload}
          loading={loadingUpload}
          disabled={!reports || reports.length <= 0}>
            Upload Reports
          </Button>
        </Form.Item>
      </Form>
      {reports.length > 0 && (
        <>
          <Title level={4}>Fetched Reports (Click Cell to modify, hit ENTER to save):</Title>
          <Button danger onClick={handleDelete} disabled={ selectedReports.length === 0}>
        Delete Selected Reports
      </Button>
          <Table
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={reports}
        columns={columns as ColumnTypes}
          />
        </>
      )}
    </div>
    </EditableContext.Provider>

  )
}

export default UploadReports
